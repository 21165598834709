import {Injectable} from '@angular/core';
import {BlobReader, ZipReader} from '@zip.js/zip.js';
import {filter, from, map, mergeAll, Observable, tap} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ZipFileService {

	public getEntries(file: File): Observable<string> {
		const zipFileReader = new BlobReader(file);
		const zipReader = new ZipReader(zipFileReader);
		return from(zipReader.getEntries()).pipe(
			tap({
				finalize: () => zipReader.close()
			}),
			mergeAll(),
			map(entry => entry.filename),
			filter(filename => !filename.includes('__MACOSX') && filename.toLowerCase().endsWith('.tsv'))
		);
	}

}
