import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../../shared/pagination/page';
import {Header} from '../../../shared/table/header';
import {ToastrMessageService} from '../../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrMessageType} from '../../../shared/toastr/toastr.message.type';
import {FolderService} from '../../_service/folder.service';
import {Folder} from '../folder';
import {CreateOrUpdateFolderModalComponent} from '../create-or-update-folder/create-or-update-folder-modal.component';
import {ModalService} from '../../../shared/modal/modal.service';

@Component({
	selector: 'app-folder-overview',
	templateUrl: './folder-overview.component.html'
})
export class FolderOverviewComponent implements OnInit {
	page$: Observable<Page<Folder>>;
	headers: Header[] = [
		{name: 'folder'}
	];
	subscription = new Subscription();
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	constructor(private folderService: FolderService,
				private modalService: ModalService,
				private toastrMessageService: ToastrMessageService,
				private translateService: TranslateService) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.folderService.search(currentPage - 1))
			);
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	openCreateModal(): void {
		this.modalService.openCreateModal(CreateOrUpdateFolderModalComponent, 'folder').subscribe(() => this.refresh$.next());
	}

	openUpdateModal(folder: Folder): void {
		this.modalService.openUpdateModal(CreateOrUpdateFolderModalComponent, folder, 'folder').subscribe(() => this.refresh$.next());
	}

	deleteFolder(folder: Folder): void {
		this.subscription.add(
			this.folderService.delete(folder.uuid)
				.subscribe({
					next: () => {
						this.toastrMessageService.show({
							type: ToastrMessageType.SUCCESS,
							messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('folder.delete.success-message')}`
						});
						this.refresh$.next();
					},
					error: (errorResponse) => {
						this.toastrMessageService.show({
							type: ToastrMessageType.ERROR,
							messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant(errorResponse.error.errorCode)}`,
							sticky: true
						});
					}
				}));
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('folder.delete.confirmation-message.are-you-sure')}</p>`;
	}
}

