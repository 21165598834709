import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Folder} from '../folder/folder';
import {CreateOrUpdateFolderRequest} from '../folder/create.or.update.folder.request';

@Injectable({
	providedIn: 'root'
})
export class FolderService {

	readonly baseUrl = environment.backend_url + '/folder';

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<Array<Folder>> {
		return this.http.get<Array<Folder>>(this.baseUrl);
	}

	search(pageNumber: number): Observable<Page<Folder>> {
		return this.http.get<Page<Folder>>(this.baseUrl + '/search', {params: {'page': pageNumber}});
	}

	create(folder: CreateOrUpdateFolderRequest): Observable<Folder> {
		return this.http.post<Folder>(this.baseUrl, folder);
	}

	update(uuid: string, folder: CreateOrUpdateFolderRequest): Observable<Folder> {
		return this.http.put<Folder>(`${this.baseUrl}/${uuid}`, folder);
	}

	delete(folderUuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${folderUuid}`);
	}

}
