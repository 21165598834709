import {Component} from '@angular/core';
import {LogoutService} from '../shared/logout/logout.service';

@Component({
	selector: 'app-no-access',
	templateUrl: './no-access.component.html'
})
export class NoAccessComponent {

	constructor(public logoutService: LogoutService) {
	}
}
