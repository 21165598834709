<form [formGroup]="form">

	<app-modular-form-control-input name="name"/>

	<app-modular-form-control-input name="icao"/>

	<app-customer-subscription-form name="distributionSubscriptions"
									[formArray]="getFormArray('distributionSubscriptions')"
									[offset]="true"
									[subscriptions]="customer?.distributionSubscriptions"/>

	<br>
	<app-customer-subscription-form name="viewerSubscriptions"
									[formArray]="getFormArray('viewerSubscriptions')"
									[subscriptions]="customer?.viewerSubscriptions"/>

	<br>
	<app-modular-form-control-multiselect name="programs" [options]="programs$ | async"/>

	<app-modular-form-control-checkbox name="suspended"/>

	<app-invalid-feedback></app-invalid-feedback>
</form>

