import {Component, OnDestroy, OnInit} from '@angular/core';
import {Header} from '../../shared/table/header';
import {Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Job} from '../_model/job';
import {JobService} from '../_service/job.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';

@Component({
	selector: 'app-job-overview',
	templateUrl: './job-overview.component.html'
})
export class JobOverviewComponent implements OnInit, OnDestroy {
	jobs$: Observable<Job[]>;
	headers: Header[] = [
		{name: 'latest-state-change'},
		{name: 'flightdatabase-name'},
		{name: 'tsv-file-name'},
		{name: 'status'}
	];

	private refresh$ = new Subject<void>();
	private subscription = new Subscription();

	constructor(private jobService: JobService,
				private toastrMessageService: ToastrMessageService,
				private translateService: TranslateService) {}

	ngOnInit(): void {
		this.jobs$ = this.refresh$.pipe(startWith('')).pipe(switchMap(() => this.jobService.getAll()));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	reprocess(job: Job): void {
		this.changeStatus(job.id, 'PENDING');
	}
	cancel(job: Job): void {
		this.changeStatus(job.id, 'CANCELED');
	}
	getConfirmationMessage(): string {
		return `<p class="mb-3">${this.translateService.instant('job.overview.confirmation-message.change-status')}</p>`
			+ `<p>${this.translateService.instant('job.overview.confirmation-message.are-you-sure')}</p>`;
	}

	private changeStatus(jobId: string, status: string): void {
		this.subscription.add(
			this.jobService.update(jobId, { status: status })
				.subscribe(() => {
					this.toastrMessageService.show({
						type: ToastrMessageType.SUCCESS,
						messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('job.overview.confirmation-message.success-message')}`
					});
					this.refresh$.next();
				}));
	}
}
