import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseUploadComponent} from '../../../shared/upload/base-upload.component';
import {FlightManagementSystemService} from '../../_service/flight-management-system.service';
import {Observable} from 'rxjs';
import {HttpEvent} from '@angular/common/http';

@Component({
	selector: 'app-fms-upload-progress',
	templateUrl: './fms-upload-progress.component.html'
})
export class FmsUploadProgressComponent extends BaseUploadComponent implements OnInit, OnDestroy {

	@Output() public resetAll = new EventEmitter();

	@Input() selectedFiles: File[];

	constructor(private fmsService: FlightManagementSystemService) {
		super();
	}

	ngOnInit(): void {
		this.upload(this.selectedFiles);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	reset(): void {
		this.resetAll.emit();
	}

	override doUploadFile(file: File): Observable<HttpEvent<any>> {
		return this.fmsService.upload(file);
	}

}
