<div class="dropdown d-flex align-items-center " [class.show]="showDropdown">
	<button class="form-select" (click)="showDropdown=!showDropdown">
		{{placeholder | translate}}
	</button>

	<div class="dropdown-content position-absolute bg-white shadow border-radius-lg px-2 py-2 top-100 mx-2">
		<div *ngFor="let dropdownItem of providedData">
			<div class="py-1 d-flex">
				<input type="checkbox" class="form-check-input shadow-lg my-0"
					   [id]="'multiselect-checkbox-' + dropdownItem.value" [checked]="isSelected(dropdownItem)"
					   (click)="selectOption(dropdownItem)">
				<label class="form-check-label ms-2" (click)="selectOption(dropdownItem)">{{ dropdownItem.label | translate}}</label>
			</div>
		</div>
	</div>
</div>
