import {NgModule} from '@angular/core';
import {CustomerService} from './_service/customer.service';
import {CustomerOverviewComponent} from './customer-overview/customer-overview.component';
import {TableModule} from '../shared/table/table.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {PaginationModule} from '../shared/pagination/pagination.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ErrorHandlingModule} from '../shared/error-handling/error-handling.module';
import {ValidatedInputModule} from '../shared/validated-input/validated-input.module';
import {ModalModule} from '../shared/modal/modal.module';
import {ToastrMessageModule} from '../shared/toastr/toastr.message.module';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {SearchModule} from '../shared/search/search.module';
import {DropdownModule} from '../shared/dropdown-multiselect/dropdown.module';
import {DownloadModule} from '../shared/download/download.module';
import {CustomerFormComponent} from './customer-form/customer-form.component';
import {CustomerFormModalComponent} from './customer-form-modal/customer-form-modal.component';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {
	CustomerSubscriptionFormComponent
} from './customer-form/customer-subscription-form/customer-subscription-form.component';

@NgModule({
	declarations: [
		CustomerOverviewComponent,
		CustomerFormComponent,
		CustomerFormModalComponent,
		CustomerSubscriptionFormComponent
	],
	imports: [
		TableModule,
		CommonModule,
		TranslateModule,
		PaginationModule,
		ReactiveFormsModule,
		ErrorHandlingModule,
		ValidatedInputModule,
		ModalModule,
		ToastrMessageModule,
		FormsModule,
		NgbDatepickerModule,
		PermissionsModule,
		NgbModule,
		DropdownModule,
		SearchModule,
		DownloadModule,
		ModularFormsModule
	],
	providers: [
		CustomerService
	]
})
export class CustomerModule {
}
