import {Component, ViewEncapsulation} from '@angular/core';
import {ModularFormControlInputWithLayout} from '../modular-form-control-layout/modular-form-control-input-with-layout';

@Component({
	selector: 'app-modular-form-control-checkbox',
	templateUrl: './modular-form-control-checkbox.component.html',
	styleUrls: ['modular-form-control-checkbox.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class ModularFormControlCheckboxComponent extends ModularFormControlInputWithLayout {

}

