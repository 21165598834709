<div class="d-flex flex-row w-100 justify-content-center mt-3">
	<div class="drop-zone d-flex flex-column justify-content-start align-items-center p-4 progress-container">
		<div class="list-group w-100">

			<div class="list-group-item" *ngFor="let progress$ of progresses; let i=index">
				<ng-container *ngIf="progress$ | async as progress">
					<div class="d-flex flex-row justify-content-start" id="file-upload-status-{{i}}">
						<i class="ti fs-1 me-2 mt-1" [class]="getFileIcon(progress)" id="file-icon-{{i}}"></i>
						<div class="d-flex flex-column">
							<p class="fw-semibold mb-0" [class.text-red-700]="progress.status === STATUS.FAILED">{{progress.file}}</p>
							<p class="fw-lighter text-grey-500" [class.text-red-700]="progress.status === STATUS.FAILED">{{getProgressLabel(progress)}}</p>
						</div>
					</div>
					<div class="d-flex flex-row justify-content-between align-items-center" id="file-upload-progress-{{i}}">
						<ngb-progressbar type="success" [value]="progress.getProgress()" height="0.5rem" class="w-100" *ngIf="progress.status === STATUS.UPLOADING"></ngb-progressbar>
						<ngb-progressbar type="danger" [value]="0" height="0.5rem" class="w-100" *ngIf="progress.status === STATUS.FAILED"></ngb-progressbar>
					</div>
				</ng-container>
			</div>
		</div>
		<div *ngIf="generalProgress | async as progress">

			<button class="btn btn-primary mt-3" disabled *ngIf="progress.status === STATUS.UPLOADING || progress.status === STATUS.PROCESSING" id="upload-in-progress">
				<span *ngIf="progress.status === STATUS.UPLOADING">{{ translate('uploading') }}</span>
				<span *ngIf="progress.status === STATUS.PROCESSING">{{ translate('processing') }}</span>
				<span class="spinner-border ms-2" role="status"></span>
			</button>

			<div *ngIf="progress.status === STATUS.DONE" class="d-flex flex-row justify-content-center align-items-center mt-3" id="succesfull-upload">
				<i class="ti ti-check fs-1 me-2"></i>
				{{ translate('success') }}
			</div>

			<div *ngIf="progress.status === STATUS.FAILED" class="mt-3" id="failed-upload">
				<div class="d-flex flex-row justify-content-center align-items-center">
					<i class="ti ti-face-id-error fs-1 me-2"></i>
					{{ translate('failure') }}
				</div>
				<ul>
					<li *ngFor="let error of progress.errors">
						{{error.errorCode | translate : this.getParams(error)}}
					</li>
				</ul>
			</div>

			<div class="d-flex flex-row justify-content-center align-items-center">
				<button class="btn btn-primary mt-3" *ngIf="progress.status === STATUS.DONE || progress.status === STATUS.FAILED" (click)="close()" id="close-button">
					<span>{{'general.close' | translate}}</span>
				</button>
			</div>
		</div>
	</div>
</div>
