<div [classList]="getContainingDivClasses()">
	<label for="{{id}}" class="form-label">{{label | translate}}</label>

	<div>
		<input id="{{id}}"
			   type="{{type}}"
			   class="{{class}}"
			   placeholder="{{ placeholder | translate}}"
			   [formControl]="control"
			   [class.has-validation]="hasErrors()"
			   [class.is-invalid]="hasErrors()"
			   [readOnly]="disabled"
			   ngbTooltip="{{tooltip | translate}}"
			   placement="bottom">

		<div class="invalid-feedback" *ngIf="hasErrors()">
			<span *ngFor="let error of getErrors()">{{error | translate}}</span>
		</div>

	</div>
</div>
