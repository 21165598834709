import {AbstractControlOptions, AsyncValidatorFn, FormGroup, ValidatorFn} from '@angular/forms';

export class NamedFormGroup extends FormGroup {
	name: string;

	constructor(name: string, controls: any, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
		super(controls, validatorOrOpts, asyncValidator);
		this.name = name;
	}
}
