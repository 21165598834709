<div [class.layout-stack]="layout === 'stack'">
	<app-modular-form-control-layout [parent]="this">
		<div [class.layout-flex]="layout === 'flex'">
			<input [id]="id()"
				   type="checkbox"
				   class="form-check-input m-1 ms-0"
				   [formControl]="formControl">
		</div>
	</app-modular-form-control-layout>
</div>
