import {UploadStep} from '../../../shared/upload/upload-step';
import {Subject} from 'rxjs';
import {UploadFormResult} from './upload-form-result';

export abstract class UploadBaseComponent {

	step: UploadStep = 'INPUT';
	reset$ = new Subject<void>();
	result: UploadFormResult;

	public submit(result: UploadFormResult): void {
		this.step = 'PROCESSING';
		this.result = result;
	}

	public reset(): void {
		this.step = 'INPUT';
		this.result = null;
		this.reset$.next();
	}

}
