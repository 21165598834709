import {Injectable} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {filter, Observable, Subject} from 'rxjs';
import {ErrorResponse} from './error-response';

@Injectable()
export class ErrorService {

	private error = new Subject<ErrorResponse>;

	constructor(router: Router) {
		router.events
			.pipe(
				filter((routeEvent: Event) => routeEvent instanceof NavigationEnd)
			)
			.subscribe(() => this.onRouteChangeSuccess());
	}

	setError(error: ErrorResponse): void {
		this.error.next(error);
	}

	onErrorChange(): Observable<ErrorResponse> {
		return this.error.asObservable();
	}

	reset(): void {
		this.setError(undefined);
	}

	private onRouteChangeSuccess(): void {
		this.reset();
	}

}
