import {Component, Input} from '@angular/core';

export type SpinnerSize = 'small' | 'medium' | 'large';

type SpinnerSizeData = {
	radius: number,
	size: number,
};

const SPINNER_SIZES: Record<SpinnerSize, SpinnerSizeData> = {
	'small': {size: 25, radius: 2},
	'medium': {size: 70, radius: 5},
	'large': {size: 110, radius: 10}
};

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.css'],
	standalone: true
})
export class SpinnerComponent {
	private _size: SpinnerSize = 'medium';

	@Input()
	set size(size: SpinnerSize) {
		this._size = size;
	}

	get sizeData(): SpinnerSizeData {
		return SPINNER_SIZES[this._size];
	}
}
