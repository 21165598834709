import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {TableModule} from '../shared/table/table.module';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {JobOverviewComponent} from './job-overview/job-overview.component';
import {ModalModule} from '../shared/modal/modal.module';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [
		JobOverviewComponent
	],
	exports: [
		JobOverviewComponent
	],
	imports: [
		TranslateModule,
		PermissionsModule,
		ModalModule,
		TableModule,
		CommonModule,
		NgbTooltip
	]
})
export class JobModule {
}
