import packageJson from '../../package.json';

export const environment = {
	backend_url: (<any>window).env['backend_url'],

	authentication: {
		authority: (<any>window).env['authentication.authority'],
		clientId: (<any>window).env['authentication.clientId'],
		secureRoutes: (<any>window).env['authentication.secureRoutes'],
		domain_url: (<any>window).env['authentication.domain_url']
	},

	analytics: {
		domain_url: (<any>window).env['analytics.domain_url'],
		measurement_id: (<any>window).env['analytics.measurement_id']
	},

	version: packageJson.version
};
