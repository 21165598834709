import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {DropdownData} from './dropdown-data';
import {ContentArray} from '../model/content-array';

@Component({
	selector: 'app-multilevel-dropdown-multiselect',
	templateUrl: './multilevel-dropdown-multiselect.component.html',
	styleUrls: ['./multilevel-dropdown-multiselect.component.css']
})
export class MultilevelDropdownMultiselectComponent {

	@Input() providedData: DropdownData[];

	@Input() placeholder: string;

	@Output() selection = new EventEmitter<ContentArray>();

	@Input() translationPrefix = '';

	@Input() placeholderIcon: string;

	@Input() resetSelectionText = 'general.clear-all-filters';

	showDropdown = false;
	selectedValues = {} as ContentArray;

	visibleSubmenu: string;

	constructor(private elementRef: ElementRef) {
	}

	isSelected(groupOption: DropdownData, option: DropdownData): boolean {
		return this.selectedValues[groupOption.value]?.indexOf(option.value) >= 0;
	}

	selectOption(groupOption: DropdownData, option: DropdownData): void {
		if (this.isSelected(groupOption, option)) {
			this.selectedValues[groupOption.value] = this.selectedValues[groupOption.value].filter(selectedValue => selectedValue !== option.value);
		} else if (this.selectedValues[groupOption.value]) {
			this.selectedValues[groupOption.value] = [...this.selectedValues[groupOption.value], option.value];
		} else {
			this.selectedValues[groupOption.value] = [option.value];
		}
		this.selection.emit(this.selectedValues);
	}

	@HostListener('document:click', ['$event'])
	clickout(event: any): void {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.showDropdown = false;
		}
	}

	openSubmenu(dropdownItem: DropdownData): void {
		if (!dropdownItem) {
			this.visibleSubmenu = null;
			return;
		}

		if (dropdownItem.subItems?.length > 0) {
			this.visibleSubmenu = dropdownItem.label;
		}
	}

	resetSelection(): void {
		this.selectedValues = {} as ContentArray;
		this.selection.emit(this.selectedValues);
	}
}
