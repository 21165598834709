import {Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionService} from './permission.service';
import {Subscription} from 'rxjs';

@Directive({
	selector: '[permissionsAllowed]' // eslint-disable-line @angular-eslint/directive-selector
})
export class PermissionsAllowedDirective implements OnDestroy {

	private serviceSubscription: Subscription;

	@Input()
	set permissionsAllowed(allowedPermissions: string[]) {
		this.refreshView(allowedPermissions);
	}

	constructor(private permissionService: PermissionService, private _viewContainer: ViewContainerRef, private _template: TemplateRef<object>) {
	}

	ngOnDestroy(): void {
		if (this.serviceSubscription) {
			this.serviceSubscription.unsubscribe();
		}
	}

	private refreshView(allowedPermissions: string[]): void {
		if (this.serviceSubscription) {
			this.serviceSubscription.unsubscribe();
		}
		this.serviceSubscription = this.permissionService.hasAtLeastOnePermission(allowedPermissions).subscribe(hasPermission => {
			if (hasPermission && this._viewContainer.length === 0) {
				this._viewContainer.createEmbeddedView(this._template);
			} else if (!hasPermission && this._viewContainer.length > 0) {
				this._viewContainer.clear();
			}
		});
	}
}
