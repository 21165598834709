import {Component, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {BaseUploadComponent} from '../../../../shared/upload/base-upload.component';
import {CustomValidators} from '../../../../shared/validators/custom-validators';
import {NamedFormGroup} from '../../../../shared/modular-forms/named-form-group';

@Component({
	selector: 'app-db-upload-form',
	templateUrl: './upload-form.component.html'
})
export class UploadFormComponent implements OnInit {

	@Input() form: NamedFormGroup;

	ngOnInit(): void {
		this.form.addControl('articleReference', new FormControl('', [Validators.required, Validators.maxLength(20)]));
		this.form.addControl('databaseName', new FormControl('', [Validators.required, Validators.maxLength(20)]));
		this.form.addControl('releaseNumber', new FormControl('', [Validators.required, Validators.maxLength(20)]));
		this.form.addControl('airacCycle', new FormControl('', [Validators.required, CustomValidators.validAirac]));
		this.form.addControl('usualDesignation', new FormControl('', [Validators.required, Validators.maxLength(100)]));
		this.form.addControl('effectiveDate', new FormControl('', [Validators.required, Validators.pattern(BaseUploadComponent.DATE_REGEX_PATTERN)]));
		this.form.addControl('endValidityDate', new FormControl('', [Validators.required, Validators.pattern(BaseUploadComponent.DATE_REGEX_PATTERN)]));
	}


}
