import {Component, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {User} from '../_model/user';
import {RoleService} from '../../role/role.service';
import {CustomerService} from '../../customer/_service/customer.service';
import {map, Observable, shareReplay} from 'rxjs';
import {SelectOption} from '../../shared/modular-forms/_model/select-option';
import {mapCustomers, mapProjects, mapRoles} from '../../shared/modular-forms/_model/select-option.factory';
import {ProjectService} from '../../project/_service/project.service';
import {ModularFormComponent} from '../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-user-form',
	templateUrl: './user-form.component.html',
	exportAs: 'userForm'
})
export class UserFormComponent extends ModularFormComponent implements OnInit {

	@Input()
	public user: Partial<User>;

	roles$: Observable<SelectOption[]>;
	customers$: Observable<SelectOption[]>;
	projects$: Observable<SelectOption[]>;
	types: SelectOption[] = [
		{value: null, label: 'user.form.type.options.no-type', translate: true, id: '0'},
		{value: 'BUYER', label: 'user.form.type.options.buyer', translate: true, id: '1'},
		{value: 'OPERATOR', label: 'user.form.type.options.operator', translate: true, id: '2'}
	];

	constructor(private roleService: RoleService,
				private customerService: CustomerService,
				private projectService: ProjectService) {
		super('user');
		this.roles$ = this.roleService.findAll().pipe(
			mapRoles(),
			shareReplay()
		);
		this.customers$ = this.customerService.findAll().pipe(
			mapCustomers(),
			shareReplay()
		);
		this.projects$ = this.projectService.getSelectableProjects().pipe(
			mapProjects(),
			shareReplay()
		);

		this.form.addControl('userName', new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z0-9-_.]*')]));
		this.form.addControl('firstName', new FormControl('', [Validators.required, Validators.maxLength(50)]));
		this.form.addControl('lastName', new FormControl('', [Validators.required, Validators.maxLength(50)]));
		this.form.addControl('email', new FormControl('', [Validators.required, Validators.maxLength(100), Validators.email]));
		this.form.addControl('role', new FormControl(null, [Validators.required]));
		this.form.addControl('customer', new FormControl(null));
		this.form.addControl('type', new FormControl(null));
		this.form.addControl('projects', new FormControl(null));

		this.form.valueChanges.subscribe(() => {
			this.updateOptionalFields();
		});
	}

	ngOnInit(): void {
		this.form.patchValue(this.user);
		if (this.isEditing()) {
			this.form.get('userName').disable();
			this.form.get('email').disable();
			this.setSelectedProjects();
		}
	}

	updateOptionalFields(): void {
		if (this.requireOptionalFields()) {
			this.form.controls['customer'].addValidators(Validators.required);
		} else {
			this.form.controls['customer'].clearValidators();
		}
	}

	requireOptionalFields(): boolean {
		return this.form.controls['role'].value?.isInternal === false;
	}

	isProductionRoleSelected(): boolean {
		return this.form.controls['role'].value?.isProductionRole === true;
	}

	private isEditing(): boolean {
		return this.user !== undefined;
	}

	private setSelectedProjects(): void {
		const projectUuids = this.user.projects.map(project => project.uuid);
		this.projects$.pipe(
			map(projects => projects.filter(project => projectUuids.indexOf(project.id) >= 0))
		)
			.subscribe(selectedProjects => this.form.get('projects').patchValue(selectedProjects));
	}

	public getUser(): User {
		const user = this.form.value;

		if (user.role.isInternal) {
			user.customer = null;
			user.type = null;
		}

		user.projectUuids = user.projects?.map((p: SelectOption) => p.id);
		if (!user.role.isProductionRole) {
			user.projects = null;
		}

		return user;
	}

}
