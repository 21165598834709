import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {UploadFileProgressComponent} from './upload-file-progress-component';

@NgModule({
	declarations: [
		UploadFileProgressComponent
	],
	exports: [
		UploadFileProgressComponent
	],
	imports: [
		TranslateModule,
		NgbModule,
		CommonModule
	],
	providers: [
	]
})
export class UploadModule {

}
