import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../_model/project';
import {FormControl, Validators} from '@angular/forms';
import {CreateOrUpdateProjectRequest} from '../_model/create-or-update-project.request';
import {ModularFormComponent} from '../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-project-form',
	templateUrl: './project-form.component.html',
	exportAs: 'projectForm'
})
export class ProjectFormComponent extends ModularFormComponent implements OnInit {

	@Input()
	public project: Project;

	constructor() {
		super('project');

		this.form.addControl('name', new FormControl('', [Validators.required, Validators.maxLength(255)]));
	}

	ngOnInit(): void {
		if (this.project) {
			this.form.patchValue(this.project);
		}
	}

	getRequest(): CreateOrUpdateProjectRequest {
		return {
			name: this.form.get('name').value
		};
	}

}
