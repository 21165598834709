<app-modular-form-control-layout [parent]="this">
	<ng-multiselect-dropdown
		[id]="id()"
		[placeholder]="translatePlaceholder()"
		[settings]="dropdownSettings"
		[data]="options"
		[disabled]="formControl.disabled"
		[formControl]="formControl"/>
	<span [class.has-validation]="hasErrors()"
		  [class.is-invalid]="hasErrors()" class="d-none">
	</span>
</app-modular-form-control-layout>
