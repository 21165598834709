import {Component} from '@angular/core';
import {UploadStep} from '../../shared/upload/upload-step';
import {Subject} from 'rxjs';
import {UploadFormResult} from './upload-form/upload-form-result';

@Component({
	selector: 'app-dataset-upload',
	templateUrl: './dataset-upload.component.html'
})
export class DatasetUploadComponent {

	step: UploadStep = 'INPUT';
	reset$ = new Subject<void>();
	result: UploadFormResult;

	public submit(result: UploadFormResult): void {
		this.step = 'PROCESSING';
		this.result = result;
	}

	public reset(): void {
		this.step = 'INPUT';
		this.reset$.next();
		this.result = null;
	}
}
