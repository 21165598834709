import {NgModule} from '@angular/core';
import {DirectDownloadComponent} from './direct-download.component';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from '../table/table.module';
import {NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';

@NgModule({
	declarations: [
		DirectDownloadComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		TableModule,
		NgbProgressbarModule
	],
	exports: [
		DirectDownloadComponent
	]
})
export class DownloadModule {
}
