import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../shared/validators/custom-validators';
import {Observable, shareReplay, Subscription} from 'rxjs';
import {CreateOrUpdateDatasetRequest} from '../../_model/create-or-update-dataset-request';
import {CreateDatasetFileData} from '../../_model/create-dataset-file-data';
import {UploadFormResult} from './upload-form-result';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {SourceRadicalIdentifierService} from '../../../sourceradicalidentifier/_service/source-radical-identifier.service';
import {datasetFileTypeOptions, mapSourceRadicalIdentifiers} from '../../../shared/modular-forms/_model/select-option.factory';
import {ModularFormComponent} from '../../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-dataset-upload-form',
	templateUrl: './dataset-upload-form.component.html'
})
export class DatasetUploadFormComponent extends ModularFormComponent implements OnInit, OnDestroy {

	@Output()
	public submitForm = new EventEmitter<UploadFormResult>();

	@Input() reset$: Observable<void>;

	sourceRadicalIdentifiers$: Observable<SelectOption[]>;
	datasetFileTypes: SelectOption[];

	private subscription = new Subscription();

	constructor(private radicalIdentifierService: SourceRadicalIdentifierService) {
		super('dataset.upload');

		this.form.addControl('usualDesignation', new FormControl('', [Validators.maxLength(100)]));
		this.form.addControl('datasetIdentifier', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('airacCycle', new FormControl('', [Validators.required, CustomValidators.validAirac]));
		this.form.addControl('releaseNumber', new FormControl(0, [Validators.required, Validators.min(0)]));
		this.form.addControl('sourceRadicalIdentifierUuid', new FormControl('', [Validators.required]));
		this.form.addControl('actualFiles', new FormControl([], [CustomValidators.requireNonEmptyArray]));
		this.form.addControl('files', new FormArray([]));

		this.sourceRadicalIdentifiers$ = this.radicalIdentifierService.findAll().pipe(
			mapSourceRadicalIdentifiers(),
			shareReplay()
		);

		this.datasetFileTypes = datasetFileTypeOptions();
	}

	ngOnInit(): void {
		this.subscription.add(this.reset$.subscribe(() => this.form.reset()));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public createFileFormGroup(file: File): FormGroup {
		const fileNameControl = new FormControl(file.name, [Validators.required]);
		fileNameControl.disable();

		return new FormGroup({
			fileName: fileNameControl,
			hash: new FormControl('', [Validators.required, Validators.maxLength(255)]),
			fileType: new FormControl('', [Validators.required])
		});
	}

	public submit(): void {
		this.submitForm.emit({
			request: this.createRequest(),
			selectedFiles: this.form.get('actualFiles').value
		});
	}

	public createRequest(): CreateOrUpdateDatasetRequest {
		const files = this.extractFiles();
		return {
			usualDesignation: this.form.get('usualDesignation').value,
			identifier: this.form.get('datasetIdentifier').value,
			sourceRadicalIdentifierUuid: this.form.get('sourceRadicalIdentifierUuid').value,
			airacCycle: this.form.get('airacCycle').value,
			releaseNumber: +this.form.get('releaseNumber').value,
			files: files
		};
	}

	private extractFiles(): CreateDatasetFileData[] {
		const filesControls = this.form.get('files') as FormArray;
		return filesControls.controls.map(controls => controls.getRawValue()).map(values => {
			return {
				fileName: values.fileName,
				hash: values.hash,
				fileType: values.fileType
			} as CreateDatasetFileData;
		});
	}
}
