import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModularFormControlInputComponent} from './modular-form-control-input/modular-form-control-input.component';
import {ModularFormControlSelectComponent} from './modular-form-control-select/modular-form-control-select.component';
import {ModularFormControlMultiselectComponent} from './modular-form-control-multiselect/modular-form-control-multiselect.component';
import {ModularFormControlStaticListComponent} from './modular-form-control-static-list/modular-form-control-static-list.component';
import {NgbDateAdapter, NgbDateParserFormatter, NgbInputDatepicker, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {ModularFormControlFilesComponent} from './modular-form-control-files/modular-form-control-files.component';
import {DndModule} from 'ngx-drag-drop';
import {ModularFormControlDateComponent} from './modular-form-control-date/modular-form-control-date.component';
import {ModularFormDynamicArrayComponent} from './modular-form-dynamic-array/modular-form-dynamic-array.component';
import {ModularFormControlLayoutComponent} from './modular-form-control-layout/modular-form-control-layout.component';
import {ModularFormControlCheckboxComponent} from './modular-form-control-checkbox/modular-form-control-checkbox.component';
import {ModularFormControlRadioGroupComponent} from './modular-form-control-radiogroup/modular-form-control-radiogroup.component';
import {ModularFormControlTextareaComponent} from './modular-form-control-textarea/modular-form-control-textarea.component';
import {CustomDateParserFormatter} from '../datepicker/custom-date-parser-formatter';
import {CustomNgbDateAdapter} from '../datepicker/custom-ngb-date-adapter';

@NgModule({
	declarations: [
		ModularFormControlInputComponent,
		ModularFormControlSelectComponent,
		ModularFormControlMultiselectComponent,
		ModularFormControlFilesComponent,
		ModularFormControlStaticListComponent,
		ModularFormControlDateComponent,
		ModularFormDynamicArrayComponent,
		ModularFormControlLayoutComponent,
		ModularFormControlCheckboxComponent,
		ModularFormControlRadioGroupComponent,
		ModularFormControlTextareaComponent
	],
	exports: [
		ModularFormControlInputComponent,
		ModularFormControlSelectComponent,
		ModularFormControlMultiselectComponent,
		ModularFormControlFilesComponent,
		ModularFormControlStaticListComponent,
		ModularFormControlDateComponent,
		ModularFormDynamicArrayComponent,
		ModularFormControlCheckboxComponent,
		ModularFormControlRadioGroupComponent,
		ModularFormControlTextareaComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		NgbTooltipModule,
		NgMultiSelectDropDownModule,
		DndModule,
		NgbInputDatepicker
	],
	providers: [
		{provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
		{provide: NgbDateAdapter, useClass: CustomNgbDateAdapter}
	]
})
export class ModularFormsModule {
}
