import {Component} from '@angular/core';
import {UploadStep} from '../../../shared/upload/upload-step';
import {UploadFormResult} from '../upload-form-result';
import {Subject} from 'rxjs';

@Component({
	selector: 'app-upload-notice',
	templateUrl: './upload-notice.component.html'
})
export class UploadNoticeComponent {

	step: UploadStep = 'INPUT';
	result: UploadFormResult;
	reset$ = new Subject<void>();

	public submit(result: UploadFormResult): void {
		this.step = 'PROCESSING';
		this.result = result;
	}

	public reset(): void {
		this.step = 'INPUT';
		this.result = null;
		this.reset$.next();
	}

}
