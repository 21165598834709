import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DigisStartRequest} from '../_model/digis.start.request';
import {DigisDb} from '../_model/digis.db';

@Injectable({
	providedIn: 'root'
})
export class DigisService {

	readonly baseUrl = environment.backend_url + '/digis';

	constructor(private http: HttpClient) {
	}

	getRecentDbs(): Observable<DigisDb[]> {
		return this.http.get<DigisDb[]>(`${this.baseUrl}/databases`);
	}

	start(digisStart: DigisStartRequest): Observable<void> {
		return this.http.post<void>(`${this.baseUrl}/start`, digisStart);
	}
}
