import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LaunchProductionArticle} from '../../_model/launch-production-article';
import {combineLatest, Observable, shareReplay, Subscription, tap} from 'rxjs';
import {DatasetService} from '../../../dataset/_service/dataset.service';
import {AbstractControl, ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective, ValidatorFn, Validators} from '@angular/forms';
import {ProductionCatalogueService} from '../../_service/production-catalogue.service';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {launchTypeOptions, mapDatasetToSelectOption} from '../../../shared/modular-forms/_model/select-option.factory';
import {ModularFormComponent} from '../../../shared/modular-forms/modular-form/modular-form.component';
import {PermissionService} from '../../../shared/permission/permission.service';
import {ProductionCatalogue} from '../../_model/production-catalogue';
import {DatasetOverview} from '../../../dataset/_model/dataset-overview';

@Component({
	selector: 'app-production-catalogue-launch-article-form',
	templateUrl: './launch-article-form.component.html',
	viewProviders: [
		{
			provide: ControlContainer,
			useExisting: FormGroupDirective
		}
	]
})
export class LaunchArticleFormComponent extends ModularFormComponent implements OnInit, OnDestroy {

	@Input() prodCat: ProductionCatalogue;

	private subscription = new Subscription();
	articleDatasets = new Map<string, SelectOption[]>;
	launchTypes: SelectOption[];
	launchArticles$: Observable<LaunchProductionArticle[]>;

	constructor(private datasetService: DatasetService,
				private productionCatalogueService: ProductionCatalogueService,
				private controlContainer: ControlContainer,
				private permissionService: PermissionService) {
		super('production-catalogue.launch');

		this.subscription.add(permissionService.getPermissions().subscribe(permissions => this.launchTypes = launchTypeOptions(permissions)));

		this.form.addControl('allArticles', new FormControl<boolean>(false));

		this.form.addControl('launchArticles', new FormArray([], [this.minSelectedArticles(1)]));
		this.form.addControl('type', new FormControl(null, [Validators.required]));

		this.getParentForm().addControl('launchForm', this.form);
	}

	ngOnInit(): void {
		this.launchArticles$ = this.productionCatalogueService.getLinkedArticles(this.prodCat.uuid).pipe(
			shareReplay()
		);

		this.form.get('allArticles').valueChanges.subscribe(v => {
			const formArray = this.form.get('launchArticles') as FormArray;
			formArray.controls.forEach(articleForm => {
				const selectedControl = (articleForm as FormGroup).get('selected');
				if (!selectedControl.disabled && selectedControl.value !== v) {
					selectedControl.setValue(v);
				}
			});
		});
		this.subscription.add(this.launchArticles$.subscribe(launchArticles => {
			const map = new Map<string, FormGroup>;
			launchArticles.forEach(launchArticle => {
				const formArray = this.form.get('launchArticles') as FormArray;
				const formGroup = this.createLaunchArticleFormGroup(launchArticle);
				formArray.push(formGroup);
				map.set(launchArticle.articleUuid, formGroup);
			});

			const observables = launchArticles.map(launchArticle => {
				return this.getLinkedDatasets(launchArticle).pipe(tap(options => {
					this.articleDatasets.set(launchArticle.articleUuid, options);
					const datasetPreselection = this.getDatasetPreselection(options);
					if (datasetPreselection) {
						map.get(launchArticle.articleUuid).get('dataset').patchValue(datasetPreselection);
					}
				}));
			});
			this.subscription.add(combineLatest(observables).subscribe());
		}));
	}

	private createLaunchArticleFormGroup(launchArticle: LaunchProductionArticle): FormGroup<any> {
		const articleFormGroup = new FormGroup({});
		const datasetControl = new FormControl({value: null, disabled: true}, [this.datasetHasSourceFile()]);
		const selectedControl = new FormControl(false);
		const airacControl = new FormControl({value: null, disabled: true});
		const releaseNumberControl = new FormControl({value: null, disabled: true});

		articleFormGroup.addControl('selected', selectedControl);
		articleFormGroup.addControl('articleReference', new FormControl({value: launchArticle.articleReference, disabled: true}));
		articleFormGroup.addControl('articleUuid', new FormControl(launchArticle.articleUuid));
		articleFormGroup.addControl('productionDate', new FormControl({value: launchArticle.productionDate, disabled: true}));
		articleFormGroup.addControl('airac', airacControl);
		articleFormGroup.addControl('releaseNumber', releaseNumberControl);
		articleFormGroup.addControl('dataset', datasetControl);

		this.subscription.add(selectedControl.valueChanges.subscribe(value => {
			if (value) {
				datasetControl.addValidators(Validators.required);
				datasetControl.enable();
			} else {
				datasetControl.removeValidators(Validators.required);
				datasetControl.disable();
			}
			datasetControl.updateValueAndValidity();
			const allArticlesSelected = this.allArticlesSelected();
			if (this.form.get('allArticles').value !== allArticlesSelected) {
				this.form.get('allArticles').setValue(allArticlesSelected, {emitEvent: false});
			}
		}));

		this.subscription.add(datasetControl.valueChanges.subscribe(dataset => {
			if (dataset && dataset.hasSourceFileAndSoc) {
				airacControl.patchValue(dataset.airacCycle !== null ? dataset.airacCycle : 'N/A');
				releaseNumberControl.patchValue(dataset.releaseNumber);
			} else if (dataset) {
				airacControl.patchValue('N/A');
				releaseNumberControl.patchValue('N/A');
			}
		}));
		return articleFormGroup;
	}

	getLinkedDatasets(article: LaunchProductionArticle): Observable<SelectOption[]> {
		return this.datasetService.getLinkedDatasets(article.radicalIdentifierUuid).pipe(
			mapDatasetToSelectOption(),
			shareReplay()
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getParentForm(): FormGroup {
		return this.controlContainer.control as FormGroup;
	}

	allArticlesSelected(): boolean {
		const formArray = this.form.get('launchArticles') as FormArray;
		return formArray.controls
			.filter(formGroup => !(formGroup as FormGroup).get('selected').disabled)
			.map(formGroup => (formGroup as FormGroup).get('selected').value)
			.reduce((a, b) => a && b, true);
	}

	minSelectedArticles(min = 1): ValidatorFn {
		const validator: ValidatorFn = (formArray: AbstractControl) => {
			if (formArray instanceof FormArray) {
				const totalSelected = formArray.controls
					.map((control) => (control as FormGroup).get('selected').value)
					.reduce((prev, next) => (next ? prev + next : prev), 0);
				return totalSelected >= min ? null : {min1Selected: true};
			}

			throw new Error('formArray is not an instance of FormArray');
		};

		return validator;
	}

	datasetHasSourceFile(): ValidatorFn {
		return (formControl: AbstractControl) => {
			if (formControl.value) {
				return formControl.value.hasSourceFileAndSoc ? null : {noSourceFileOrSoc: true};
			}
			return null;
		};
	}

	getDatasetPreselection(options: SelectOption[]): DatasetOverview {
		return options
			.filter(option => option.value.airacCycle === this.prodCat.airacCycle)
			.filter(option=> option.value.hasSourceFileAndSoc)
			.reduce((a, b) => (a?.value && b?.value && a.value.releaseNumber > b.value.releaseNumber) ? a : b, null)
			?.value;
	}

}
