import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import {DownloadableFile} from './downloadable-file';

export class BaseDownloadComponent<T extends DownloadableFile> {
	downloading = false;
	files: T[];
	entityUuid: string;

	constructor(public activeModal: NgbActiveModal) {
	}

	close(): void {
		if (!this.downloading) {
			this.activeModal.dismiss();
		}
	}

	setContext(entityUuid: string, files: T[]): void {
		this.files = files;
		this.entityUuid = entityUuid;
	}

	setDownloading(downloading: boolean): void {
		this.downloading = downloading;
		this.activeModal.update({backdrop: !downloading} as NgbModalOptions);
	}

	isDownloading(): boolean {
		return this.downloading;
	}
}
