import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {ErrorService} from './error.service';
import {ErrorResponse} from './error-response';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	constructor(private errorService: ErrorService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req)
			.pipe(tap({
				error: error => {
					if (error instanceof HttpErrorResponse && error.error) {
						if (!error.error.multiple) {
							this.errorService.setError({
								errorCode: error.error.errorCode,
								params: error.error.params
							} as ErrorResponse);
						}
					}
				}
			}));
	}

}
