<div class="d-inline-flex justify-content-between w-100 align-items-center">
	<p class="current-page-text">
		<ng-container *ngIf="page.totalElements > 0; else noEntries">
			{{'pagination.showing' | translate}}
			<strong>{{((currentPage - 1) * page.pageable.pageSize) + 1}}</strong>
			{{'pagination.to' | translate}}
			<strong>{{currentPage * page.pageable.pageSize > page.totalElements ? page.totalElements : currentPage * page.pageable.pageSize}}</strong>
			{{'pagination.of' | translate}}
			<strong>{{page.totalElements}}</strong>
			{{'pagination.entries' | translate}}
		</ng-container>

		<ng-template #noEntries>
			{{'pagination.showing' | translate}}
			<strong>0</strong>
			{{'pagination.entries' | translate}}
		</ng-template>
	</p>
	<nav *ngIf="page.totalPages > 1">
		<ul class="pagination">
			<li class="page-item page-back">
				<a id="load-first-page" (click)="loadPage(1)" class="page-link" [class.disabled]="currentPage === 1">
					&laquo;
				</a>
			</li>
			<li class="page-item page-back">
				<a id="load-previous-page" (click)="loadPage(currentPage - 1)" class="page-link"
				   [class.disabled]="currentPage === 1">
					&lt;
				</a>
			</li>
			<li *ngFor="let page of getPages()" class="page-item ">
				<a id="page-{{page}}-anchor" (click)="loadPage(page)" class="page-link"
				   [class.fw-bold]="currentPage === page" [class.bg-lightgrey]="currentPage === page">{{page}}</a>
			</li>
			<li class="page-item page-forward">
				<a id="load-next-page" (click)="loadPage(currentPage + 1)" class="page-link"
				   [class.disabled]="currentPage === page.totalPages || page.totalPages === 0">
					&gt;
				</a>
			</li>
			<li class="page-item page-forward">
				<a id="load-last-page" (click)="loadPage(page.totalPages)" class="page-link"
				   [class.disabled]="currentPage === page.totalPages || page.totalPages === 0">
					&raquo;
				</a>
			</li>
		</ul>
	</nav>
</div>
