<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{ 'digis.form.title' | translate }}</h1>
	</div>

	<div *ngIf="recentDbs$ | async as databases" class="d-flex flex-column mb-5">
		<label class="form-label">{{ 'digis.db.locations' | translate }}</label>
		<app-table [id]="'recent-db-table'"
				   [headers]="headers"
				   [removeStickyHeader]="true"
				   [headerNamePrefix]="'digis.db.headers.'">
			<ng-container>
				<tr *ngFor="let db of databases">
					<td>{{ db.databaseName }}</td>
					<td>{{ db.fileName }}</td>
					<td>{{ db.hash }}</td>
				</tr>
			</ng-container>
		</app-table>
	</div>

	<hr>

	<div class="d-flex mb-5">
		<form [formGroup]="form" class="row flex-xxl-row">
			<div class="col-12 col-xxl-6">
				<app-modular-form-control-input name="articleName1"></app-modular-form-control-input>
				<app-modular-form-control-input name="articleProgram1"></app-modular-form-control-input>
				<app-modular-form-control-input name="articleEquipment1"></app-modular-form-control-input>
				<app-modular-form-control-input name="articleDbRadical1"></app-modular-form-control-input>
				<app-modular-form-control-input name="articleDsp1"></app-modular-form-control-input>
			</div>
			<div class="col-12 col-xxl-6">
				<app-modular-form-control-input name="articleName2"></app-modular-form-control-input>
				<app-modular-form-control-input name="articleProgram2"></app-modular-form-control-input>
				<app-modular-form-control-input name="articleEquipment2"></app-modular-form-control-input>
				<app-modular-form-control-input name="articleDbRadical2"></app-modular-form-control-input>
				<app-modular-form-control-input name="articleDsp2"></app-modular-form-control-input>
			</div>
			<hr>
			<app-modular-form-control-input name="airacCycle"></app-modular-form-control-input>
			<app-modular-form-control-input name="A424Source"></app-modular-form-control-input>
			<app-modular-form-control-input name="A424SourceSHA256"></app-modular-form-control-input>
		</form>
	</div>

	<div class="card-footer" *ngIf="!info">
		<button id="send" type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="send()">{{ 'digis.form.send' | translate }}</button>
	</div>
	<div class="form-label" *ngIf="info">
		{{ info }}
	</div>
</div>
