<div class="modal-body" id="confirmationModalBody">
	<div class="d-flex flex-column align-items-center text-center">
		<i class="ti mb-3 mt-3 fs-1" [ngClass]="this.modalConfiguration?.modalIcon"></i>
		<div [innerHTML]="this.modalConfiguration?.confirmationMessage"></div>
	</div>
</div>
<div id="footer" class="d-flex flex-row justify-content-center" [ngClass]="this.modalConfiguration.hasFooter ? 'modal-footer' : ''">
	<button id="cancel-button" type="button" class="btn btn-secondary me-3" (click)="cancel()" [disabled]="submitting">
		{{this.modalConfiguration?.cancelButtonText | translate}}
	</button>
	<button id="confirm-button" type="button" [ngClass]="modalConfiguration.danger ? 'btn-danger': 'btn-warning'"  class="btn" (click)="confirm()" [disabled]="submitting">
		{{this.modalConfiguration?.confirmButtonText | translate}}
	</button>
</div>
