<div class="dropdown">
	<button class="btn btn-secondary dropdown-toggle" (click)="showDropdown=!showDropdown" id="show-dropdown">
		<i *ngIf="placeholderIcon" class="ti mx-1" [class]="placeholderIcon"></i>
		<label>{{placeholder | translate}}</label>
	</button>

	<div class="dropdown-menu mt-1" [class.show]="showDropdown">
		<li *ngFor="let dropdownItem of providedData" class="dropdown-submenu dropend text-nowrap"
			(mouseenter)="openSubmenu(dropdownItem)" id="{{'dropdown-item-' + dropdownItem.value}}">

			<div class="dropdown-item d-flex justify-content-between">
				{{ translationPrefix + dropdownItem.label | translate}}
				<i class="ti ti-chevron-right fs-4 ms-4"></i>
			</div>

			<ul *ngIf="dropdownItem.subItems?.length >= 1" class="dropdown-menu dropdown-submenu pt-3 w-auto"
				[class.show]="visibleSubmenu === dropdownItem.label">
				<li *ngFor="let subItem of dropdownItem.subItems" class="mb-2 px-3 d-flex align-items-center">
					<input type="checkbox" class="form-check-input my-0"
						   [id]="'multiselect-checkbox-' + subItem.value"
						   [checked]="isSelected(dropdownItem, subItem)"
						   (click)="selectOption(dropdownItem, subItem)">
					<label class="form-check-label ms-3"
						   (click)="selectOption(dropdownItem, subItem)">{{ (dropdownItem.translate ? translationPrefix : '') + subItem.label | translate}}</label>
				</li>
			</ul>

			<div *ngIf="!dropdownItem.subItems" class="py-1 d-flex">
				<input type="checkbox" class="form-check-input shadow-lg"
					   [id]="'multiselect-checkbox-' + dropdownItem.value"
					   [checked]="isSelected(dropdownItem, dropdownItem)"
					   (click)="selectOption(dropdownItem, dropdownItem)">
				<label class="form-check-label ms-2"
					   (click)="selectOption(dropdownItem, dropdownItem)">{{ (dropdownItem.translate ? translationPrefix : '') + dropdownItem.label | translate}}</label>
			</div>
		</li>

		<li class="dropdown-divider"></li>

		<li>
			<button class="dropdown-item text-danger" (mouseenter)="openSubmenu(null)" (click)="resetSelection()"
					id="reset-selection">
				{{resetSelectionText | translate}}
			</button>
		</li>
	</div>
</div>
