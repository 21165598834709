import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {ModularFormComponent} from '../../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-fms-upload-form',
	templateUrl: './fms-upload-form.component.html'
})
export class FmsUploadFormComponent extends ModularFormComponent {

	@Input() reset$: Observable<void>;

	@Output() public submitForm = new EventEmitter<File[]>();

	constructor() {
		super('fms.upload');

		this.form.addControl('files', new FormControl([]));
	}

	public submit(): void {
		const files = this.form.get('files').value;
		this.submitForm.emit(files);
	}
}
