import {Component, OnDestroy, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Subscription} from 'rxjs';
import {AutoLoginService} from './auto.login.service';

@Component({
	selector: 'app-auto-component',
	template: ''
})
export class AutoLoginComponent implements OnInit, OnDestroy {

	private subscription = new Subscription();

	constructor(private oidcSecurityService: OidcSecurityService,
				private autoLoginService: AutoLoginService) {
	}

	ngOnInit(): void {
		this.subscription.add(this.oidcSecurityService.checkAuth().subscribe((loginResponse) => {
			if (!loginResponse.isAuthenticated) {
				this.oidcSecurityService.authorize();
			} else {
				this.subscription.add(this.autoLoginService.redirectUserToCorrectLandingPage().subscribe());
			}
		}));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
