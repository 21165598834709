import {NgModule} from '@angular/core';
import {DbOverviewComponent} from './overview/db-overview.component';
import {TranslateModule} from '@ngx-translate/core';
import {DndModule} from 'ngx-drag-drop';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidatedInputModule} from '../shared/validated-input/validated-input.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FlightDatabaseService} from './_service/flight-database.service';
import {TableModule} from '../shared/table/table.module';
import {PaginationModule} from '../shared/pagination/pagination.module';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {ModalModule} from '../shared/modal/modal.module';
import {DbViewerComponent} from './db-viewer/db-viewer.component';
import {DbUpdatesViewerComponent} from './db-updates-viewer/db-updates-viewer.component';
import {TsvFileSelectorComponent} from './tsv-file-selector/tsv-file-selector.component';
import {DropdownModule} from '../shared/dropdown-multiselect/dropdown.module';
import {UploadModule} from '../shared/upload/upload.module';
import {RouterModule} from '@angular/router';
import {DownloadModule} from '../shared/download/download.module';
import {SearchModule} from '../shared/search/search.module';
import {UploadViewerComponent} from './upload/upload-viewer/upload-viewer.component';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {UploadViewerFormComponent} from './upload/upload-viewer/upload-viewer-form/upload-viewer-form.component';
import {UploadProgressComponent} from './upload/upload-base/upload-progress/upload-progress.component';
import {UploadFormComponent} from './upload/upload-base/upload-form/upload-form.component';
import {UploadDbComponent} from './upload/upload-db/upload-db.component';
import {UploadDbFormComponent} from './upload/upload-db/upload-db-form/upload-db-form.component';
import {SpinnerComponent} from '../shared/spinner/spinner.component';
import {DbDownloadFilesModalComponent} from './download-files-modal-component/db-download-files-modal.component';
import {DbDeleteFilesModalComponent} from './delete-files-modal-component/db-delete-files-modal.component';

@NgModule({
	declarations: [
		DbOverviewComponent,
		DbDownloadFilesModalComponent,
		DbDeleteFilesModalComponent,
		DbViewerComponent,
		DbUpdatesViewerComponent,
		TsvFileSelectorComponent,
		UploadViewerComponent,
		UploadViewerFormComponent,
		UploadDbComponent,
		UploadDbFormComponent,
		UploadProgressComponent,
		UploadFormComponent
	],
	imports: [
		ModularFormsModule,
		TranslateModule,
		DndModule,
		CommonModule,
		ReactiveFormsModule,
		ValidatedInputModule,
		NgbModule,
		TableModule,
		PaginationModule,
		UploadModule,
		PermissionsModule,
		ModalModule,
		FormsModule,
		DropdownModule,
		RouterModule,
		DownloadModule,
		SearchModule,
		SpinnerComponent
	],
	providers: [
		FlightDatabaseService
	]
})
export class DbModule {

}
