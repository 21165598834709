import {NgModule} from '@angular/core';
import {SidebarComponent} from './sidebar.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {LogoutModule} from '../shared/logout/logout.module';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {CommonModule} from '@angular/common';

@NgModule({
	declarations: [
		SidebarComponent
	],
	exports: [
		SidebarComponent
	],
	imports: [
		TranslateModule,
		RouterModule,
		LogoutModule,
		PermissionsModule,
		CommonModule
	]
})
export class SidebarModule {

}
