import {NgModule} from '@angular/core';
import {ToastrMessageService} from './toastr.message.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrMessagesComponent} from './toastr.messages.component';
import {NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
	providers: [
		ToastrMessageService
	],
	imports: [
		BrowserAnimationsModule,
		NgbToastModule,
		TranslateModule
	],
	declarations: [
		ToastrMessagesComponent
	],
	exports: [
		ToastrMessagesComponent
	]
})
export class ToastrMessageModule {

}
