<div class="d-flex flex-row align-items-center gap-2">
	<span>{{'db.viewer.navdb' | translate}}</span>
	<select class="form-select" id="select-flightdatabase" ngModel (ngModelChange)="selectFlightDatabase($event)">
		<option selected [ngValue]="null" disabled>{{"db.viewer.select-database" | translate}}</option>
		<option *ngFor="let flightDatabase of allFlightDatabases$ | async" [ngValue]="flightDatabase">
			<div *ngIf="flightDatabase.tsvState === 'DONE'">
				{{flightDatabase.databaseName}} - {{flightDatabase.airacCycle}}
			</div>
			<div *ngIf="flightDatabase.tsvState !== 'DONE'">
				{{flightDatabase.databaseName}} - {{flightDatabase.airacCycle}} ({{"db.viewer." + flightDatabase.tsvState | lowercase | translate}})
			</div>
		</option>
	</select>

	<span>{{'db.viewer.section-subsection' | translate}}</span>
	<select class="form-select" id="select-flightdatabase-tsv-file" [(ngModel)]="selectedFlightDatabaseTsvFile"
            (ngModelChange)="selectTsvFile($event)">
		<option selected [value]="null" disabled>{{"db.viewer.select-database-tsv-file" | translate}}</option>
		<ng-container *ngIf="selectedFlightDatabase">
			<option *ngFor="let file of selectedFlightDatabase.tsvFiles" [ngValue]="file">
				{{ file.displayName || file.fileName }}
			</option>
		</ng-container>
	</select>
</div>
