<div class="modal-header">
	<h1 class="modal-title fs-5">
		<span *ngIf="!program">{{"program.create.modal.title" | translate}}</span>
		<span *ngIf="program">{{"program.update.modal.title" | translate}}</span>
	</h1>
	<button type="button" class="btn-close" (click)="close()" [disabled]="submitting"></button>
</div>
<div class="modal-body">
	<div class="mb-3">
		<form [formGroup]="programForm">
			<app-validated-input [id]="'program-name'"
								 placeholder="program.create.modal.form.enter-name"
								 [control]="getControl('name')"
								 [validationPrefix]="'program.validation.name.'"
								 label="program.create.modal.form.name">
			</app-validated-input>
		</form>
	</div>
	<app-invalid-feedback></app-invalid-feedback>
</div>
<div class="modal-footer">
	<button id="close" type="button" class="btn btn-secondary" (click)="close()"
			[disabled]="submitting">{{"general.close" | translate}}</button>
	<button id="save-program" type="button" class="btn btn-primary" (click)="save()"
			[disabled]="programForm.invalid || submitting">
		<span *ngIf="!program">{{"general.create" | translate}}</span>
		<span *ngIf="program">{{"general.save" | translate}}</span>
	</button>
</div>
