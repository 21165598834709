<div [id]="id() + '-zone'"
	 [dndAllowExternal]="true"
	 (dndDrop)="filesDropped($event)"
	 class="position-relative mb-3 p-2 rounded bg-lightgrey dropzone"
	 dndDragoverClass="dropzone-drag"
	 dndDropzone>

	<div class="text-center p-3">
		<input [id]="id()" (change)="filesSelected($event)"
			   type="file" [accept]="accept" multiple class="d-none">

		<label [for]="id()" class="text-center position-absolute w-100 h-100 top-0 start-0 pointer">
			<div class="text-center py-2">
				{{ translateLabel() }}
			</div>
		</label>
	</div>

	<ul class="list-group">
		<ng-container *ngFor="let file of formControl.value;let i=index">
			<li class="list-group-item d-flex justify-content-between align-items-center">
				{{file.name}}
				<span [id]="id() + '-remove-' + i" class="remove-badge" (click)="deleteFile(file)"><i
					class="ti ti-x"></i></span>
			</li>
		</ng-container>
	</ul>
</div>
