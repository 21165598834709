import {Component, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {Header} from '../../shared/table/header';
import {FlightDatabaseService} from '../_service/flight-database.service';
import {FlightDatabaseTsvFileData} from '../_model/flight-database-tsv-file-data';
import {FlightDatabase} from '../_model/flight-database';
import {Content} from '../../shared/model/content';
import {DropdownData} from '../../shared/dropdown-multiselect/dropdown-data';
import {HttpResponse} from '@angular/common/http';
import {mapContentToContentArray} from '../../shared/model/content-array';
import {Page} from '../../shared/pagination/page';
import {TsvDiffLine} from '../_model/tsv-diff-line';

@Component({
	selector: 'app-db-updates-viewer',
	templateUrl: './db-updates-viewer.component.html',
	styleUrls: ['./db-updates-viewer.component.css']
})
export class DbUpdatesViewerComponent implements OnDestroy {

	public readonly DIFF_COLUMN = 'db.updates-viewer.headers.diff';
	loading= false;
	subscription = new Subscription();
	private currentPage$ = new Subject<number>();
	selectedFlightDatabase$ = new ReplaySubject<FlightDatabase>(1);
	selectedFlightDatabaseTsvFile$ = new ReplaySubject<FlightDatabaseTsvFileData>(1);
	updatesOnly$ = new BehaviorSubject(false);
	resetSearch$ = new Subject<void>();
	headers: Header[];
	viewSelectionDropdownData: DropdownData[];
	selectedCriteria: Content;
	currentTsvFileUuid: string;
	currentDbUuid: string;
	searchResult: Page<TsvDiffLine>;

	constructor(private dbService: FlightDatabaseService) {
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page - 1);
		this.search(this.selectedCriteria, page - 1);
	}

	flightDatabaseSelected(flightDatabase: any): void {
		this.currentDbUuid = flightDatabase.uuid;
		this.selectedFlightDatabase$.next(flightDatabase);
		this.selectedFlightDatabaseTsvFile$.next(null);
	}

	tsvFileSelected(file: any): void {
		this.subscription.add(this.selectedFlightDatabase$.subscribe(db => this.dbService.logViewerUpdatesConsults(db.uuid, file.uuid)));

		this.extractHeaders(file);
		this.updateVisibleColumns(this.headers.map(header => header.name));

		this.resetSearch();

		this.viewSelectionDropdownData = this.headers.map(header => {
			return {
				label: header.name,
				value: header.name,
				isSelected: true
			};
		});

		this.currentTsvFileUuid = file.uuid;

		this.selectedFlightDatabaseTsvFile$.next(file);
	}

	updateUpdatesOnly(event: any): void {
		this.updatesOnly$.next(event);
	}

	updateVisibleColumns(columns: string[]): void {
		this.headers.forEach(header => {
			header.visible = columns.indexOf(header.name) >= 0;
		});
	}

	search(selectedCriteria: Content, page: number = 0): void {
		this.selectedCriteria = selectedCriteria;
		this.loading = true;

		console.log(page);
		this.subscription.add(this.updatesOnly$.subscribe((updatesOnly) => {
			this.dbService.searchTsvDiffLines(page, this.currentDbUuid, this.currentTsvFileUuid, updatesOnly, selectedCriteria).subscribe(
				(value) => {
					this.searchResult = value;
				}
			).add(() => {
				this.loading = false;
			});
		}));
	}

	resetSearch(): void {
		this.searchResult = null;
		this.resetSearch$.next();
	}

	getExportFunction(): Observable<HttpResponse<Blob>> {
		return this.dbService.exportTsvDiffLines(this.currentDbUuid, this.currentTsvFileUuid, mapContentToContentArray(this.selectedCriteria));
	}

	private extractHeaders(file: any): void {
		this.headers = file.columnHeaders.map((headerValue: string) => {
			return {name: headerValue, searchable: true};
		}) as Header[];
		this.headers.splice(0, 0, {name: this.DIFF_COLUMN, searchable: true});
	}
}
