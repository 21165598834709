import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {PermissionsAllowedDirective} from './permission.allowed.directive';
import {PermissionService} from './permission.service';

@NgModule({
	declarations: [
		PermissionsAllowedDirective
	],
	imports: [
		CommonModule,
		HttpClientModule
	],
	providers: [PermissionService],
	exports: [PermissionsAllowedDirective]
})
export class PermissionsModule {

}
