import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Header} from '../../shared/table/header';
import {DigisService} from '../_service/digis.service';
import {DigisDb} from '../_model/digis.db';
import {DigisStartRequest} from '../_model/digis.start.request';
import {ModularFormComponent} from '../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-digis-form',
	templateUrl: './digis-form.component.html'
})
export class DigisFormComponent extends ModularFormComponent implements OnDestroy, OnInit {
	private subscription = new Subscription();

	headers: Header[] = [
		{name: 'name'},
		{name: 'path'},
		{name: 'hash'}
	];
	recentDbs$: Observable<DigisDb[]>;

	info = '';

	ngOnInit(): void {
		this.recentDbs$ = this.digisService.getRecentDbs();
	}

	constructor(private digisService: DigisService) {
		super('digis');

		this.form.addControl('articleName1', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('articleProgram1', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('articleEquipment1', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('articleDbRadical1', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('articleDsp1', new FormControl('', [Validators.required, Validators.maxLength(255)]));

		this.form.addControl('articleName2', new FormControl('prefilled-for-testing', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('articleProgram2', new FormControl('ATR', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('articleEquipment2', new FormControl('FMS200_ATR-STD2', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('articleDbRadical2', new FormControl('K9172', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('articleDsp2', new FormControl('NVB', [Validators.required, Validators.maxLength(255)]));

		this.form.addControl('airacCycle', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('A424Source', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('A424SourceSHA256', new FormControl('', [Validators.required, Validators.maxLength(255)]));
	}

	get articles(): FormArray {
		return this.form.get('articles') as FormArray;
	}

	send(): void {
		this.subscription.add(this.digisService.start(this.createRequest()).subscribe(() => {
			this.info = 'Form sent';
			setTimeout(() => this.info = '', 2000);
		}));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private createRequest(): DigisStartRequest {
		return {
			articles: [{
				name: this.form.get('articleName1').value,
				program: this.form.get('articleProgram1').value,
				equipment: this.form.get('articleEquipment1').value,
				dbRadical: this.form.get('articleDbRadical1').value,
				dsp: this.form.get('articleDsp1').value
			}, {
				name: this.form.get('articleName2').value,
				program: this.form.get('articleProgram2').value,
				equipment: this.form.get('articleEquipment2').value,
				dbRadical: this.form.get('articleDbRadical2').value,
				dsp: this.form.get('articleDsp2').value
			}],
			airacCycle: this.form.get('airacCycle').value,
			A424Source: this.form.get('A424Source').value,
			A424SourceSHA256: this.form.get('A424SourceSHA256').value
		};
	}

}
