import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseUploadComponent} from '../../../shared/upload/base-upload.component';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {Observable, Subscription} from 'rxjs';
import {FolderService} from '../../_service/folder.service';
import {ProgramService} from '../../_service/program.service';
import {CustomerService} from '../../../customer/_service/customer.service';
import {mapCustomers, mapFolders, mapPrograms} from '../../../shared/modular-forms/_model/select-option.factory';
import {CustomValidators} from '../../../shared/validators/custom-validators';
import {CreateNoticeRequest} from '../../model/create-notice-request';
import {UploadFormResult} from '../upload-form-result';
import {ModularFormComponent} from '../../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-upload-notice-form',
	templateUrl: './upload-notice-form.component.html'
})
export class UploadNoticeFormComponent extends ModularFormComponent implements OnDestroy {

	@Input() reset$: Observable<void>;

	@Output() public submitForm = new EventEmitter<UploadFormResult>();

	protected readonly levels: SelectOption[] = [
		{id: 'DOCUMENT', value: 'DOCUMENT', label: 'notice.upload.form.level.options.document', translate: true},
		{
			id: 'INFORMATION',
			value: 'INFORMATION',
			label: 'notice.upload.form.level.options.information',
			translate: true
		},
		{id: 'SAFETY', value: 'SAFETY', label: 'notice.upload.form.level.options.safety', translate: true},
		{id: 'NON_SAFETY', value: 'NON_SAFETY', label: 'notice.upload.form.level.options.non-safety', translate: true}
	];

	makeAvailableFor: SelectOption[] = [
		{
			id: 'ALL_CUSTOMERS',
			value: 'ALL_CUSTOMERS',
			label: 'notice.upload.form.makeAvailableFor.options.all_customers',
			translate: true
		},
		{
			id: 'CUSTOMERS',
			value: 'CUSTOMERS',
			label: 'notice.upload.form.makeAvailableFor.options.customers',
			translate: true
		},
		{
			id: 'PROGRAMS',
			value: 'PROGRAMS',
			label: 'notice.upload.form.makeAvailableFor.options.programs',
			translate: true
		}
	];

	folders$: Observable<SelectOption[]>;
	programs$: Observable<SelectOption[]>;
	customers$: Observable<SelectOption[]>;

	private subscription = new Subscription();

	constructor(folderService: FolderService,
				programService: ProgramService,
				customerService: CustomerService) {
		super('notice.upload');

		this.folders$ = folderService.findAll().pipe(mapFolders());
		this.programs$ = programService.findAll().pipe(mapPrograms());
		this.customers$ = customerService.findAll().pipe(mapCustomers());

		this.form.addControl('reference', new FormControl('', [Validators.required, Validators.maxLength(20)]));
		this.form.addControl('title', new FormControl('', [Validators.required, Validators.maxLength(255)]));

		this.form.addControl('makeAvailableFor', new FormControl('', [Validators.required]));
		this.form.addControl('availableCustomerUuids', new FormControl({value: [], disabled: true}, [CustomValidators.requireNonEmptyArray]));
		this.form.addControl('availableProgramUuids', new FormControl({value: [], disabled: true}, [CustomValidators.requireNonEmptyArray]));
		this.form.addControl('allCustomers', new FormControl(false));
		this.form.addControl('notifyCustomers', new FormControl(false));

		this.form.addControl('level', new FormControl(null, [Validators.required]));
		this.form.addControl('folderUuid', new FormControl({value: null, disabled: true}, [Validators.required]));
		this.form.addControl('effectiveDate', new FormControl({value: null, disabled: true}, [Validators.required, Validators.pattern(BaseUploadComponent.DATE_REGEX_PATTERN)]));
		this.form.addControl('endValidityDate', new FormControl({value: null, disabled: true}, [Validators.required, Validators.pattern(BaseUploadComponent.DATE_REGEX_PATTERN)]));

		this.form.addControl('actualFiles', new FormControl([], [CustomValidators.requireNonEmptyArray]));
		this.form.addControl('files', new FormArray([]));

		this.subscription.add(this.form.get('level').valueChanges.subscribe((value) => {
			this.form.get('folderUuid').disable();
			this.form.get('effectiveDate').disable();
			this.form.get('endValidityDate').disable();

			if (value === 'DOCUMENT') {
				this.form.get('folderUuid').enable();
			} else {
				this.form.get('effectiveDate').enable();
				this.form.get('endValidityDate').enable();
			}

			this.form.updateValueAndValidity();
		}));

		this.subscription.add(this.form.get('makeAvailableFor').valueChanges.subscribe(value => {
			this.form.get('availableCustomerUuids').disable();
			this.form.get('availableProgramUuids').disable();

			if (value == 'CUSTOMERS') {
				this.form.get('availableCustomerUuids').enable();
			} else if (value == 'PROGRAMS') {
				this.form.get('availableProgramUuids').enable();
			}

			this.form.updateValueAndValidity();
		}));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getControlValue(name: string): any {
		return this.form.get(name).value;
	}

	fileMetaGroupFactory(): (file: File) => FormGroup {
		return (file: File) => {
			const fileNameControl = new FormControl(file.name, [Validators.required]);
			fileNameControl.disable();

			return new FormGroup({
				fileName: fileNameControl,
				hash: new FormControl('', [Validators.required, Validators.maxLength(255)])
			});
		};
	}

	submit(): void {
		this.submitForm.emit({
			request: this.createRequest(),
			selectedFiles: this.form.get('actualFiles').value
		});
	}

	createRequest(): CreateNoticeRequest {
		const request = {...this.form.getRawValue()};
		delete request['actualFiles'];

		if (request['makeAvailableFor'] === 'CUSTOMERS') {
			request['customerUuids'] = request['availableCustomerUuids'].map((option: SelectOption) => option.id);
		} else if (request['makeAvailableFor'] === 'PROGRAMS') {
			request['programUuids'] = request['availableProgramUuids'].map((option: SelectOption) => option.id);
		} else if (request['makeAvailableFor'] === 'ALL_CUSTOMERS') {
			request['allCustomers'] = true;
		}

		delete request['makeAvailableFor'];
		delete request['availableCustomerUuids'];
		delete request['availableProgramUuids'];
		return request;
	}
}
