import {Content} from './content';

export interface ContentArray {
	[id: string]: string[]
}

export function mapContentToContentArray(content: Content): ContentArray {
	const contentArray: ContentArray = {};
	for (const key in content) {
		if (Object.prototype.hasOwnProperty.call(content, key)) {
			contentArray[key] = [content[key]];
		}
	}
	return contentArray;
}
