import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {ZipFileService} from '../../../_service/zip-file.service';
import {CustomValidators} from '../../../../shared/validators/custom-validators';
import {UploadFormResult} from '../../upload-base/upload-form-result';
import {CreateFlightDatabaseRequest} from '../../../_model/create-flight-database-request';
import {ModularFormComponent} from '../../../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-db-upload-viewer-form',
	templateUrl: './upload-viewer-form.component.html'
})
export class UploadViewerFormComponent extends ModularFormComponent implements OnInit, OnDestroy {

	@Input() reset$: Observable<void>;

	@Output() public submitForm = new EventEmitter<UploadFormResult>();

	private subscription = new Subscription();

	constructor(private zipFileService: ZipFileService) {
		super('db.upload');

		this.form.addControl('actualFiles', new FormControl([], [CustomValidators.requireNonEmptyArray]));
	}

	ngOnInit(): void {
		this.subscription.add(this.reset$.subscribe(() => this.form.reset()));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public createFileFormGroup(): (file: File) => FormGroup {
		return (file: File) => {
			const fileNameControl = new FormControl(file.name, [Validators.required]);
			fileNameControl.disable();

			const tsvFiles = new FormArray([]);
			this.subscription.add(this.zipFileService.getEntries(file).subscribe(filename =>
				this.addTsvFileGroup(filename, tsvFiles)));

			return new FormGroup({
				fileName: fileNameControl,
				hash: new FormControl('', [Validators.required, Validators.maxLength(255)]),
				tsvFiles: tsvFiles
			});
		};
	}

	private addTsvFileGroup(filename: string, tsvFiles: FormArray): void {
		const fileNameControl = new FormControl(filename, [Validators.required]);
		fileNameControl.disable();

		const path = filename.split('/');
		const suggestedDisplayName = path[path.length - 1]
			.slice(0, -4).replaceAll(/_|\s{2}/g, ' ').trim();

		tsvFiles.controls.push(new FormGroup({
			fileName: fileNameControl,
			displayName: new FormControl(suggestedDisplayName, [Validators.required])
		}));
	}

	public getTsvFileControls(index: number): AbstractControl[] {
		return (this.form.get('tsvFiles.' + index + '.tsvFiles') as FormArray).controls;
	}

	public submit(): void {
		this.submitForm.emit({
			request: this.createRequest(),
			selectedFiles: this.form.get('actualFiles').value
		});
	}

	createRequest(): CreateFlightDatabaseRequest {
		const request = {...this.form.getRawValue()};
		delete request['actualFiles'];
		return request;
	}

}
