import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {forkJoin, map, Observable, of} from 'rxjs';
import {ServerInfo} from './info';

@Injectable({
	providedIn: 'root'
})
export class InfoService {

	private readonly baseUrl = environment.backend_url + '/info';
	private readonly frontendVersion = environment.version;

	constructor(private http: HttpClient) {
	}

	getVersion(): Observable<string> {
		return forkJoin([this.getFrontendVersion(), this.getBackendVersions()])
			.pipe(map(result => result[0] + ' / ' + result[1]));
	}

	getFrontendVersion(): Observable<string> {
		return of<string>(this.frontendVersion);
	}

	getBackendVersions(): Observable<string> {
		return this.http.get<ServerInfo>(this.baseUrl).pipe(map(obj => obj.portalVersion + ' / '+ obj.diffCalculatorVersion));
	}
}
