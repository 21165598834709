import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {SearchComponent} from './search.component';
import {FormsModule} from '@angular/forms';

@NgModule({
	declarations: [
		SearchComponent
	],
	exports: [
		SearchComponent
	],
	imports: [
		TranslateModule,
		FormsModule
	]
})
export class SearchModule {

}
