import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit, OnDestroy {

	@Output() searchEvent = new EventEmitter<string>();
	@Input() placeHolder: string;
	@Input() inputFieldId: string;
	@Input() resetSearch = new Observable<void>();

	private subscription = new Subscription();

	searchValue: string = null;

	search(event: any): void {
		this.searchValue = event.target.value?.trim();
		this.searchEvent.next(this.searchValue);
	}

	ngOnInit(): void {
		this.subscription.add(this.resetSearch.subscribe({
			next: () => this.searchValue = null
		}));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
