<form [formGroup]="form" (ngSubmit)="this.submit()">

	<app-modular-form-control-files name="files"/>

	<div class="d-flex flex-row justify-content-end">
		<input id='upload-button' type="submit" class="btn btn-primary"
			   [value]="'fms.upload.submit' | translate"
			   [disabled]="form.invalid"/>
	</div>
</form>
