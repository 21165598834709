import {NgModule} from '@angular/core';
import {RoleService} from './role.service';

@NgModule({
	providers: [
		RoleService
	]
})
export class RoleModule {

}
