import {Component, OnInit} from '@angular/core';
import {map, Observable, startWith} from 'rxjs';
import {ModularFormControlInputWithLayout} from '../modular-form-control-layout/modular-form-control-input-with-layout';

@Component({
	selector: 'app-modular-form-control-static-list',
	templateUrl: './modular-form-control-static-list.component.html'
})
export class ModularFormControlStaticListComponent extends ModularFormControlInputWithLayout implements OnInit {

	values$: Observable<string[]>;

	override ngOnInit(): void {
		super.ngOnInit();

		this.values$ = this.formControl.valueChanges.pipe(
			startWith(this.formControl.value),
			map(value => this.extractValues(value))
		);
	}

	private extractValues(value: any): string[] {
		if (value) {
			if (Array.isArray(value)) {
				return value.map(v => v.toString());
			} else {
				return [value.toString()];
			}
		}
		return [];
	}
}
