import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeoAreaMap} from '../_model/geo-area-map';

@Injectable({
	providedIn: 'root'
})
export class GeoAreaService {

	readonly baseUrl = environment.backend_url + '/geo-area';

	constructor(private http: HttpClient) {
	}

	getAll(): Observable<GeoAreaMap> {
		return this.http.get<GeoAreaMap>(`${this.baseUrl}`);
	}

}
