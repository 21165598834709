import {Component, OnDestroy} from '@angular/core';
import {FlightDatabaseService} from '../_service/flight-database.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize, Observable, Subject, Subscription} from 'rxjs';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {FlightDatabase} from '../_model/flight-database';

@Component({
	selector: 'app-db-delete-files-modal-component',
	templateUrl: './db-delete-files-modal.component.html'
})
export class DbDeleteFilesModalComponent implements OnDestroy {

	flightDatabase: FlightDatabase;
	submitting = false;

	private onDeletion$: Subject<void>;
	private subscription = new Subscription();

	constructor(public flightDatabaseService: FlightDatabaseService,
				public activeModal: NgbActiveModal,
				public toastrMessageService: ToastrMessageService,
				public translateService: TranslateService) {
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	close(): void {
		this.activeModal.dismiss();
	}

	setContext(flightDatabase: FlightDatabase, onDeletion: Subject<void>): void {
		this.flightDatabase = flightDatabase;
		this.onDeletion$ = onDeletion;
	}

	deleteViewerDatabases(): void {
		this.performBlockingAction(this.flightDatabaseService.deleteTsvFiles(this.flightDatabase.uuid));
	}

	deleteBinaryDatabases(): void {
		this.performBlockingAction(this.flightDatabaseService.deleteDbFiles(this.flightDatabase.uuid));
	}

	deleteAllDatabases(): void {
		this.performBlockingAction(this.flightDatabaseService.deleteDatabase(this.flightDatabase.uuid));
	}

	private performBlockingAction(action: Observable<void>): void {
		if (this.submitting) {
			return;
		}
		this.submitting = true;
		this.subscription.add(
			action.pipe(
				finalize(() => {
					this.submitting = false;
				})
			).subscribe(this.showSuccessMessage())
		);
	}

	private showSuccessMessage() {
		return (): void => {
			this.toastrMessageService.show({
				type: ToastrMessageType.SUCCESS,
				messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('db.overview.delete.success-message')}`
			});
			this.onDeletion$.next();
			this.activeModal.close();
		};
	}
}
