import {Component, OnDestroy, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Router} from '@angular/router';
import {AutoLoginService} from './auto.login.service';
import {of, Subscription, switchMap, tap} from 'rxjs';

@Component({
	selector: 'app-home',
	template: ''
})
export class HomeComponent implements OnInit, OnDestroy {

	subscription = new Subscription();

	constructor(private oidcSecurityService: OidcSecurityService,
				private router: Router,
				private autoLoginService: AutoLoginService) {
	}

	ngOnInit(): void {
		this.subscription.add(this.oidcSecurityService.isAuthenticated$.pipe(
			switchMap(({isAuthenticated}) => {
				if (!isAuthenticated) {
					return of(null).pipe(tap(() => this.router.navigate(['autologin'])));
				}
				return this.autoLoginService.redirectUserToCorrectLandingPage();
			})
		).subscribe());
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
