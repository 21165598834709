<div id="toast-container" class="toast-container position-fixed bottom-0 end-0 p-3 pb-5 mb-4">
	<ngb-toast
		*ngFor="let toast of toastrMessageService.toasts"
		id="toastr-{{toast.getId()}}"
		[class]="getClassNames(toast)"
		[autohide]="getAutoHide(toast)" [delay]="5000"
		(hidden)="toastrMessageService.close(toast.getId())">

		<ng-container *ngIf="toast.getToastrMessage().message">
			<div>{{toast.getToastrMessage().message | translate : toast.getToastrMessage().messageParameters}}</div>
		</ng-container>

		<ng-container *ngIf="toast.getToastrMessage().messageHTML">
			<div [innerHTML]="toast.getToastrMessage().messageHTML | translate: toast.getToastrMessage().messageParameters"></div>
		</ng-container>

		<button type="button" class="btn-close me-2 m-auto" (click)="toastrMessageService.close(toast.getId())" aria-label="Close"></button>
	</ngb-toast>
</div>
