<div class="modal-header">
	<h1 class="modal-title fs-5">
		<span>{{ title }}</span>
	</h1>
	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
			(click)="closeModal()"
			[disabled]="disabled">
	</button>
</div>
<div class="modal-body">
	<ng-content></ng-content>
</div>
<div class="modal-footer">
	<button id="close" type="button" class="btn btn-secondary" data-bs-dismiss="modal"
			(click)="closeModal()"
			[disabled]="disabled">

		{{"general.close" | translate}}
	</button>
	<ng-content select="[actions]"></ng-content>
</div>
