<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{'customer.overview.title' | translate}}</h1>
	</div>

	<div *ngIf="page$ | async as page">
		<div *ngIf="page.totalElements > 0 || searchValue; else noCustomers">
			<div class="d-inline-flex flex-row justify-content-between w-100 mb-3">
				<app-search inputFieldId="customer-search-field"
							placeHolder="customer.overview.search.placeholder"
							(searchEvent)="search($event)">
				</app-search>

				<button id="open-create-customer-modal" *permissionsAllowed="['CREATE_CUSTOMERS']" type="button" class="btn btn-primary" (click)="openCreateModal()">
					<i class="ti ti-plus me-2 fs-4"></i>{{'customer.overview.create-button' | translate}}
				</button>
			</div>

			<app-table [id]="'customer-table'"
					   [headers]="headers"
					   [headerNamePrefix]="'customer.overview.headers.'"
					   [addButtonColumn]="true">
				<ng-container *ngIf="page.totalElements > 0 else noResults">
					<tr *ngFor="let customer of page.content; let i  = index">
						<td>{{ customer.name }}</td>
						<td>{{ customer.icao }}</td>
						<td>
							{{ getProgramsCommaSeperated(customer.programs)}}
						</td>
						<td>
							<div class="d-flex justify-content-end align-items-center">
								<i id="edit-customer-{{i}}" *permissionsAllowed="['UPDATE_CUSTOMERS']" class="ti ti-pencil me-2 fs-4 pointer" (click)="openUpdateModal(customer)"></i>
								<i id="delete-customer-{{i}}" *permissionsAllowed="['DELETE_CUSTOMERS']" class="ti ti-trash text-danger me-2 fs-4 pointer"
								   appConfirmationModal
								   (confirmAction)="deleteCustomer(customer)"
								   confirmationMessage="{{getConfirmationMessage()}}">
								</i>
								<i id="view-customer-{{i}}" class="ti ti-eye me-2 fs-4 pointer" (click)="openViewModal(customer)"></i>
							</div>
						</td>
					</tr>
				</ng-container>
			</app-table>
			<app-pagination [page]="page" (pageEvent)="loadPage($event)"></app-pagination>
			<div class="d-inline-flex flex-row justify-content-end w-100 mb-3 mt-3">
				<app-direct-download [exportFunction]="getExportFunction()" [filePrefix]="'customer_overview'"></app-direct-download>
			</div>

		</div>
	</div>

</div>

<ng-template #noResults>
	<tr>
		<td colspan="100%">
			<p class="empty-state-text my-4 justify-content-center">{{'customer.overview.search.no-results' | translate}}</p>
		</td>
	</tr>
</ng-template>

<ng-template #noCustomers>
	<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
		<p class="empty-state-text mb-4">{{'customer.overview.no-content' | translate}}</p>
		<button id="open-create-customer-modal-no-customers" class="btn btn-primary" (click)="openCreateModal()">
			<i class="ti ti-plus me-2 fs-4"></i>{{'customer.overview.create-button' | translate}}
		</button>
	</div>
</ng-template>
