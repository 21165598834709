import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomNgbDateAdapter extends NgbDateAdapter<string> {
	static readonly DELIMITER = '/';

	fromModel(value: string | null): NgbDateStruct | null {
		if (value) {
			const date = value.split(CustomNgbDateAdapter.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10)
			};
		}
		return null;
	}

	toModel(date: NgbDateStruct | null): string | null {
		return CustomNgbDateAdapter.format(date);
	}

	static format(date: NgbDateStruct | null): string | null {
		if (date) {
			return (date.day < 10 ? '0' : '') + date.day + CustomNgbDateAdapter.DELIMITER + (date.month < 10 ? '0' : '') + date.month + this.DELIMITER + date.year;
		}
		return null;
	}
}
