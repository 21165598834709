<app-base-modal [title]="i18nTitle | translate" [disabled]="submitting">
	<app-customer-form #form="customerForm" [customer]="customer" [readonly]="readonly || submitting"/>
	<ng-container actions *ngIf="!readonly">
		<button id="submit-customer" type="button" class="btn btn-primary"
				(click)="submitForm(form.getCreateOrUpdateRequest())"
				[disabled]="form.isInvalid() || submitting">
			{{ i18nSubmit | translate }}
		</button>
	</ng-container>
</app-base-modal>

