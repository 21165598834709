import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectOverviewComponent} from './project-overview/project-overview.component';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from '../shared/table/table.module';
import {ModalModule} from '../shared/modal/modal.module';
import {ToastrMessageModule} from '../shared/toastr/toastr.message.module';
import {ProjectService} from './_service/project.service';
import {PaginationModule} from '../shared/pagination/pagination.module';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {ProjectFormModalComponent} from './project-form-modal/project-form-modal.component';
import {ProjectFormComponent} from './project-form/project-form.component';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ErrorHandlingModule} from '../shared/error-handling/error-handling.module';

@NgModule({
	declarations: [
		ProjectOverviewComponent,
		ProjectFormModalComponent,
		ProjectFormComponent
	],
	exports: [
		ProjectOverviewComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		TableModule,
		ModalModule,
		ToastrMessageModule,
		PaginationModule,
		PermissionsModule,
		ModularFormsModule,
		ReactiveFormsModule,
		ErrorHandlingModule
	],
	providers: [
		ProjectService
	]
})
export class ProjectModule {
}
