import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from './confirmation/confirmation.modal.component';
import {ConfirmationModalDirective} from './confirmation/confirmation.modal.directive';
import {CommonModule} from '@angular/common';
import {ModalService} from './modal.service';
import {BaseModalComponent} from './base/base.modal.component';

@NgModule({
	declarations: [
		ConfirmationModalComponent,
		ConfirmationModalDirective,
		BaseModalComponent
	],
	imports: [
		NgbModule,
		TranslateModule,
		CommonModule
	],
	providers: [
		ModalService
	],
	exports: [
		ConfirmationModalDirective,
		BaseModalComponent
	]
})
export class ModalModule {

}
