import {NgModule} from '@angular/core';
import {TableComponent} from './table.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {PermissionsModule} from '../permission/permissions.module';
import {SearchModule} from '../search/search.module';

@NgModule({
	declarations: [
		TableComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		PermissionsModule,
		SearchModule
	],
	exports: [
		TableComponent
	]
})
export class TableModule {
}
