<div class="modal-header">
	<h1 class="modal-title fs-5">
		<span>{{'db.overview.delete.title' | translate}}</span>
	</h1>
	<button type="button" class="btn-close" (click)="close()"></button>
</div>
<div class="modal-body">
	<div class="mb-4">
		<div class="d-flex justify-content-between flex-row">
			<h2 class="h5">{{'db.overview.delete.db-files' | translate}}</h2>
			<i id="delete-dbs"
			   class="ti ti-trash float-end text-danger fs-4 pointer"
			   appConfirmationModal
			   (confirmAction)="deleteBinaryDatabases()"
				[confirmationMessage]="'db.overview.delete.confirmation-message-db.are-you-sure' | translate">
			</i>
		</div>

		<div *ngFor="let file of flightDatabase?.files; let i  = index">
			<div class="row">
				<div class="col d-inline-flex mt-2">
					<span id="db-file-{{i}}" class="mx-1">{{ file.fileName }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="mb-4">
		<div class="d-flex justify-content-between flex-row">
			<h2 class="h5">{{'db.overview.delete.viewer-files' | translate}}</h2>
			<i id="delete-viewer-dbs"
			   class="ti ti-trash float-end text-danger fs-4 pointer"
			   appConfirmationModal
			   (confirmAction)="deleteViewerDatabases()"
			   [confirmationMessage]="'db.overview.delete.confirmation-message-viewer.are-you-sure' | translate">
			</i>
		</div>

		<div *ngFor="let file of flightDatabase?.tsvFiles; let i  = index">
			<div class="row">
				<div class="col d-inline-flex mt-2">
					<span id="tsv-file-{{i}}" class="mx-1">{{ file.displayName }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button id="close" type="button" class="btn btn-secondary" (click)="close()">
			{{ 'general.go-back' | translate }}
		</button>
		<button id="delete-all" type="button" class="btn btn-primary"
				appConfirmationModal
				(confirmAction)="deleteAllDatabases()"
				[confirmationMessage]="'db.overview.delete.confirmation-message-all.are-you-sure' | translate">
			<span>{{'db.overview.delete.all-files' | translate}}</span>
		</button>
	</div>
</div>
