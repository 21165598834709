import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {ContactComponent} from './contact.component';

@NgModule({
	declarations: [
		ContactComponent
	],
	exports: [
		ContactComponent
	],
	imports: [
		TranslateModule,
		CommonModule
	]
})
export class ContactModule {

}
