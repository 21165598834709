<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{'notice.document-overview.title' | translate}}</h1>
	</div>

	<div *ngIf="notices as notices">
		<div class="d-inline-flex flex-row justify-content-between align-items-center w-100 mb-3">
			<ul class="nav nav-pills gap-3 secondary">
				<li *ngFor="let folder of folders" class="nav-item">
					<button id="sorting-{{folder}}" class="nav-link" [class.active]="activeSorting === folder" (click)="setSorting(folder)">
						{{folder}}
					</button>
				</li>
			</ul>

			<a [routerLink]="'/notice-upload'" *permissionsAllowed="['UPLOAD_NOTICES']" class="text-decoration-none">
				<button type="button" class="btn btn-primary">
					<i class="ti ti-upload me-2 fs-4"></i>{{'general.upload' | translate}}
				</button>
			</a>
		</div>
		<div *ngIf="sortedNotices.length > 0; else noNotices">
			<app-table [id]="'notice-document-table'"
					   [headers]="headers"
					   [headerNamePrefix]="'notice.document-overview.headers.'"
					   [addButtonColumn]="true"
					   [nrOfButtonColumns]="2">
				<ng-container>
					<tr *ngFor="let notice of sortedNotices; let i  = index">
						<td>{{ notice.publicationDate }}</td>
						<td>{{ notice.reference }}</td>
						<td>{{ notice.title}}</td>
						<td>{{ getProgramsCommaSeparated(notice.programs) }}</td>
						<td class="fill-remaining">
							<div>
								<button class="float-start btn btn-table w-lg-td btn-download"
										id="download-notice-{{i}}"
										(click)="openDownloadModal(notice.uuid, notice.files)">
									<i class="ti ti-file-download fs-4 me-2">
									</i>
									{{notice.files.length}} {{'notice.document-overview.files' | translate}}
								</button>
							</div>
						</td>
						<td>
							<div class="d-flex justify-content-end align-items-center">
								<i id="delete-notice-{{i}}" *permissionsAllowed="['DELETE_NOTICES']"
								   class="ti ti-trash float-end text-danger me-2 fs-4 pointer"
								   appConfirmationModal
								   (confirmAction)="deleteNotice(notice)"
								   confirmationMessage="<p>{{ 'notice.document-overview.delete.confirmation-message.are-you-sure' | translate }}</p>"
								>
								</i>
								<i id="view-notice-{{i}}" *permissionsAllowed="['VIEW_NOTICE_DETAILS']" class="ti ti-eye me-2 fs-4 pointer" (click)="openViewModal(notice)"></i>
							</div>
						</td>
					</tr>
				</ng-container>
			</app-table>
		</div>
	</div>
</div>

<ng-template #noNotices>
	<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
		<p class="empty-state-text mb-4">{{'notice.document-overview.no-content' | translate}}</p>
	</div>
</ng-template>
