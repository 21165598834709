import {NgModule} from '@angular/core';
import {AutoLoginComponent} from './auto.login.component';
import {HomeComponent} from './home.component';
import {RoleBasedLandingComponent} from './role.based.landing.component';
import {AutoLoginService} from './auto.login.service';
import {NoAccessComponent} from './no-access.component';
import {TranslateModule} from '@ngx-translate/core';
import {LogoutModule} from '../shared/logout/logout.module';

@NgModule({
	declarations: [
		AutoLoginComponent,
		HomeComponent,
		RoleBasedLandingComponent,
		NoAccessComponent
	],
	providers: [
		AutoLoginService
	],
	imports: [
		TranslateModule,
		LogoutModule
	]
})
export class AuthModule {

}
