<div class="row h-100 min-vh-100">
	<div class="col d-inline-flex w-100 flex-column justify-content-center align-items-center overflow-hidden">

		<div class="d-flex flex-column w-50 align-items-center">
			<img src="assets/img/logo.png" class="w-25 mb-3" alt=""/>
			<img src="assets/img/logo-name.png" class="w-25" alt=""/>
		</div>

		<div id="no-access-message" class="d-inline-flex w-50 flex-column mt-5 align-items-center">
			<p class="text-center">
				{{'security.no-access' | translate}} <br>
				<a href="mailto:aviodata.subscription@aviobook.aero"
				   class="textlink text-decoration-none text-decoration-none">aviodata.subscription&#64;aviobook.aero</a>
			</p>
		</div>

		<div class="d-inline-flex w-50 flex-column mt-5 align-items-center">
			<a id="logout-button" class="btn btn-primary" (click)="logoutService.logout()">{{'security.go-back-to-login' | translate}}</a>
		</div>

	</div>
</div>
