import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseUploadComponent} from '../../../shared/upload/base-upload.component';
import {Observable} from 'rxjs';
import {HttpEvent} from '@angular/common/http';
import {DatasetService} from '../../_service/dataset.service';
import {CreateOrUpdateDatasetRequest} from '../../_model/create-or-update-dataset-request';
import {GeneralUploadProgress} from '../../../shared/upload/general.upload.progress';
import {UploadProgressStatus} from '../../../shared/upload/upload.progress.status';

@Component({
	selector: 'app-dataset-upload-progress',
	templateUrl: './dataset-upload-progress.component.html'
})
export class DatasetUploadProgressComponent extends BaseUploadComponent implements OnInit, OnDestroy {

	@Input() selectedFiles: File[];

	@Input() request: CreateOrUpdateDatasetRequest;

	@Output() public resetAll = new EventEmitter();

	constructor(private datasetService: DatasetService) {
		super();
	}

	ngOnInit(): void {
		this.upload(this.selectedFiles);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	reset(): void {
		this.resetAll.emit();
	}

	override doUploadFile(file: File, uploadUuid: string): Observable<HttpEvent<any>> {
		return this.datasetService.uploadFile(file, uploadUuid);
	}

	override createEntity(uploadUuid: string): Observable<any> {
		this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.PROCESSING));
		return this.datasetService.createDataset(this.request, uploadUuid);
	}
}
