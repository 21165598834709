import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Notice} from '../notice/notice';
import {CreateNoticeRequest} from '../model/create-notice-request';
import {StoredFile} from '../../db/_model/stored-file';

@Injectable({
	providedIn: 'root'
})
export class NoticeService {

	readonly baseUrl = environment.backend_url + '/notice';

	constructor(private http: HttpClient) {
	}

	getAll(): Observable<Notice[]> {
		return this.http.get<Notice[]>(this.baseUrl);
	}

	getAllDocuments(): Observable<Notice[]> {
		return this.http.get<Notice[]>(`${this.baseUrl}/documents`);
	}

	uploadFile(file: File, uploadUuid: string): Observable<HttpEvent<any>> {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('uploadUuid', uploadUuid);
		return this.http.post(`${this.baseUrl}/upload`, formData, {
			reportProgress: true,
			observe: 'events'
		});
	}

	createNotice(request: CreateNoticeRequest, uploadUuid: string): Observable<any> {
		return this.http.post(`${this.baseUrl}/create`, request, {params: {'uploadUuid': uploadUuid}});
	}

	downloadFiles(uuid: string, fileUuid: string): Observable<StoredFile[]> {
		if (fileUuid) {
			return this.http.get<StoredFile[]>(`${this.baseUrl}/download/${uuid}/${fileUuid}`);
		}
		return this.http.get<StoredFile[]>(`${this.baseUrl}/download/${uuid}`);
	}

	delete(noticeUuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${noticeUuid}`);
	}
}
