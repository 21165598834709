import {Injectable} from '@angular/core';
import {ToastrMessage} from './toastr.message';
import {ToastrInstance} from './toastr.instance';

@Injectable({
	providedIn: 'root'
})
export class ToastrMessageService {

	toasts: ToastrInstance[] = [];

	public show(toastrMessage: ToastrMessage): number {
		if (toastrMessage.message && toastrMessage.messageHTML) {
			throw new Error('You must supply a message or messageHTML, not both at the same time');
		}
		if (!toastrMessage.message && !toastrMessage.messageHTML) {
			throw new Error('You must supply a message or messageHTML');
		}

		const nextId = this.getNextId();
		this.toasts.push(new ToastrInstance(nextId, toastrMessage));
		return nextId;
	}

	public close(toastrId: number): void {
		this.toasts = this.toasts.filter(toast => toast.getId() != toastrId);
	}

	private getNextId(): number {
		return this.toasts
			.map(toast => toast.getId())
			.reduce((a, b) => Math.max(a, b), 0) + 1;
	}
}
