<div class="d-flex bd-highlight mb-3">
	<div><h1>{{ 'production-catalogue.characteristics.title' | translate }}</h1></div>
	<div class="ms-auto mt-4">
		<div class="d-flex justify-content-end gap-3">
			<button id="close" type="button" class="btn btn-secondary"
					(click)="closePage.emit()">{{ "general.go-back" | translate }}
			</button>
			<div id="dots" class="dropdown"
				 (click)="content.classList.toggle('dropdown-content-visible'); content.classList.toggle('dropdown-content-hidden')">
				<button class="btn btn-tertiary dropbtn">
					{{ "production-catalogue.characteristics.more" | translate }}
					<i class="ti ti-dots fs-4 ms-1"></i>
				</button>
				<div class="dropdown-content pointer " #content>
					<ng-container *permissionsAllowed="['EXPORT_PRODUCTION_CATALOGUES']">
						<a (click)="exportProductionCatalogue()">
							<i id="export-production-catalogue" class="ti ti-download pt-5 fs-4 pointer position-relative top-10 me-1"></i>
							<span class="pointer">{{ 'general.export' | translate }}</span>
						</a>
					</ng-container>
					<ng-container *permissionsAllowed="['EXPORT_PRODUCTION_CATALOGUES']">
						<a (click)="exportProductionCatalogueArticles()">
							<i id="export-production-catalogue-articles" class="ti ti-download pt-5 fs-4 pointer position-relative top-10 me-1"></i>
							<span class="pointer">{{ 'production-catalogue.export-articles' | translate }}</span>
						</a>
					</ng-container>
					<a appConfirmationModal
					   (confirmAction)="confirmDelete(productionCatalogue.uuid)"
					   confirmationMessage="{{getConfirmationMessage()}}"
					   *permissionsAllowed="['DELETE_PRODUCTION_CATALOGUES']">
						<i id="delete-catalogue"
						   class="ti ti-trash text-danger pt-5 fs-4 pointer position-relative top-10 me-1">
						</i>
						<span class="pointer text-danger">{{ 'general.delete' | translate }}</span>
					</a>
				</div>
			</div>
			<button id="create-catalogue" type="button" class="btn btn-primary"
					*permissionsAllowed="['UPDATE_PRODUCTION_CATALOGUES']"
					(click)="editCatalogue.emit(productionCatalogue.uuid)" [disabled]="!form.form.valid">
				<span>{{ "general.edit" | translate }}</span>
			</button>
		</div>
	</div>
</div>

<app-production-catalogue-form #form="productioncatalogueForm" [productionCatalogue]="productionCatalogue"
							  [readonly]="true"></app-production-catalogue-form>
