import {Component, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {FlightDatabaseService} from '../_service/flight-database.service';
import {TsvLine} from '../_model/tsv-line';
import {FlightDatabase} from '../_model/flight-database';
import {FlightDatabaseTsvFileData} from '../_model/flight-database-tsv-file-data';
import {Content} from '../../shared/model/content';
import {DropdownData} from '../../shared/dropdown-multiselect/dropdown-data';
import {HttpResponse} from '@angular/common/http';
import {mapContentToContentArray} from '../../shared/model/content-array';

@Component({
	selector: 'app-db-viewer',
	templateUrl: './db-viewer.component.html'
})
export class DbViewerComponent implements OnDestroy {
	loading= false;
	subscription = new Subscription();
	private currentPage$ = new Subject<number>();
	selectedFlightDatabase$ = new ReplaySubject<FlightDatabase>(1);
	selectedFlightDatabaseTsvFile$ = new ReplaySubject<FlightDatabaseTsvFileData>(1);
	resetSearch$ = new Subject<void>();
	headers: Header[];
	viewSelectionDropdownData: DropdownData[];

	selectedCriteria: Content;
	currentTsvFileUuid: string;
	currentDbUuid: string;
	searchResult: Page<TsvLine>;

	constructor(private dbService: FlightDatabaseService) {
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page - 1);
		this.search(this.selectedCriteria, page - 1);
	}

	flightDatabaseSelected(flightDatabase: any): void {
		this.currentDbUuid = flightDatabase.uuid;
		this.selectedFlightDatabase$.next(flightDatabase);
		this.selectedFlightDatabaseTsvFile$.next(null);
	}

	updateVisibleColumns(columns: string[]): void {
		this.headers.forEach(header => {
			header.visible = columns.indexOf(header.name) >= 0;
		});
	}

	tsvFileSelected(file: any): void {
		this.subscription.add(this.selectedFlightDatabase$.subscribe(db => this.dbService.logViewerConsults(db.uuid, file.uuid)));

		this.extractHeaders(file);
		this.updateVisibleColumns(this.headers.map(header => header.name));

		this.resetSearch();

		this.viewSelectionDropdownData = this.headers.map(header => {
			return {
				label: header.name,
				value: header.name,
				isSelected: true
			};
		});

		this.currentTsvFileUuid = file.uuid;

		this.selectedFlightDatabaseTsvFile$.next(file);
	}

	search(selectedCriteria: Content, page: number = 0): void {
		this.selectedCriteria = selectedCriteria;
		this.loading = true;
		this.subscription.add(this.dbService.searchTsvLines(page, this.currentDbUuid, this.currentTsvFileUuid, selectedCriteria).subscribe(
			(value) => {
				this.searchResult = value;
			}
		).add(() => {
			this.loading = false;
		}));
	}

	resetSearch(): void {
		this.searchResult = null;
		this.resetSearch$.next();
	}

	getExportFunction(): Observable<HttpResponse<Blob>> {
		return this.dbService.exportTsv(this.currentDbUuid, this.currentTsvFileUuid, mapContentToContentArray(this.selectedCriteria));
	}

	private extractHeaders(file: any): void {
		this.headers = file.columnHeaders.map((headerValue: string) => {
			return {name: headerValue, searchable: true};
		}) as Header[];
	}
}
