<div class="modal-header">
	<h1 class="modal-title fs-5">
		<span *ngIf="!folder">{{"folder.create.modal.title" | translate}}</span>
		<span *ngIf="folder">{{"folder.update.modal.title" | translate}}</span>
	</h1>
	<button type="button" class="btn-close" (click)="close()" [disabled]="submitting"></button>
</div>
<div class="modal-body">
	<div class="mb-3">
		<form [formGroup]="folderForm">
			<app-validated-input [id]="'folder-name'"
								 placeholder="folder.create.modal.form.enter-name"
								 [control]="getControl('name')"
								 [validationPrefix]="'folder.validation.name.'"
								 label="folder.create.modal.form.name">
			</app-validated-input>
		</form>
	</div>
	<app-invalid-feedback></app-invalid-feedback>
</div>
<div class="modal-footer">
	<button id="close" type="button" class="btn btn-secondary" (click)="close()"
			[disabled]="submitting">{{"general.close" | translate}}</button>
	<button id="save-folder" type="button" class="btn btn-primary" (click)="save()"
			[disabled]="folderForm.invalid || submitting">
		<span *ngIf="!folder">{{"general.create" | translate}}</span>
		<span *ngIf="folder">{{"general.save" | translate}}</span>
	</button>
</div>
