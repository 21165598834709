import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {FlightDatabase} from '../_model/flight-database';
import {FlightDatabaseTsvFileData} from '../_model/flight-database-tsv-file-data';
import {FlightDatabaseService} from '../_service/flight-database.service';

@Component({
	selector: 'app-tsv-file-selector',
	templateUrl: './tsv-file-selector.component.html'
})
export class TsvFileSelectorComponent implements OnInit, OnDestroy {

	@Output() flightDatabaseSelected = new EventEmitter<FlightDatabase>();
	@Output() tsvFileSelected = new EventEmitter<FlightDatabaseTsvFileData>();
	allFlightDatabases$: Observable<FlightDatabase[]>;
	subscription = new Subscription();
	selectedFlightDatabase: FlightDatabase;
	selectedFlightDatabaseTsvFile: FlightDatabase;

	constructor(private dbService: FlightDatabaseService) {
	}

	ngOnInit(): void {
		this.allFlightDatabases$ = this.dbService.getAllDatabasesForViewer();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	selectFlightDatabase(flightDatabase: any): void {
		this.flightDatabaseSelected.next(flightDatabase);
		this.selectedFlightDatabase = flightDatabase;
		this.selectedFlightDatabaseTsvFile = null;
	}

	selectTsvFile(file: any): void {
		this.tsvFileSelected.next(file);
	}
}
