<app-modular-form-control-layout [parent]="this">
	<div *ngFor="let option of options" [class.layout-flex]="layout === 'flex'">
		<input type="radio"
			   [formControl]="formControl"
			   [id]="id() + '-' + option.id"
			   [value]="option.value"
			   class="mx-2 ms-0">
		<label [for]="id() + '-' + option.id"
			   class="form-label ms-1 mt-1">
			{{ option.label | translate}}
		</label>
	</div>
</app-modular-form-control-layout>
