import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {TableModule} from '../shared/table/table.module';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {DigisFormComponent} from './digis-form/digis-form.component';
import {ValidatedInputModule} from '../shared/validated-input/validated-input.module';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
	declarations: [
		DigisFormComponent
	],
	imports: [
		TranslateModule,
		PermissionsModule,
		ReactiveFormsModule,
		ModularFormsModule,
		TableModule,
		CommonModule,
		ValidatedInputModule
	]
})
export class DigisModule {
}
