<form [formGroup]="form">
	<ng-container *ngIf="productionCatalogue && !_readonly && oldStatus?.value !== 'REDACTION_IN_PROGRESS' && !launch">
		<div class="d-flex w-75">
			<div class="col px-md-1">
				<div class="alert alert-info alert-dismissible fade show" role="alert">
					<span [innerHTML]="informationKeyForCurrentState | translate"></span>
				</div>
			</div>
		</div>
	</ng-container>

	<div class="d-flex w-75" *ngIf="productionCatalogue && !copy">

		<div class="col px-md-1">
			<app-modular-form-control-select name="status" [options]="productionCatalogueStatuses"/>

		</div>
		<div class="col px-md-1">
			<app-modular-form-control-input name="approvalDate"></app-modular-form-control-input>
		</div>
	</div>

	<div class="d-flex w-75">
		<div class="col px-md-1">
			<app-modular-form-control-input name="identification"></app-modular-form-control-input>
		</div>
		<div class="col px-md-1">
			<app-modular-form-control-input name="airacCycle"></app-modular-form-control-input>
		</div>
	</div>

	<div class="d-flex w-75">
		<div class="col px-md-1">
			<app-modular-form-control-input name="requesterUser"></app-modular-form-control-input>
		</div>
		<div class="col px-md-1">
			<app-modular-form-control-select name="project"
											 [options]="projects$ | async"></app-modular-form-control-select>
		</div>
	</div>

	<ng-container *ngIf="articles$ | async as articles">
		<app-production-catalogue-article-form *ngIf="!launch"
											   [articles]="articles"
											   [prodCat]="productionCatalogue"
											   [readonly]="_readonly"
											   [form]="form"></app-production-catalogue-article-form>
	</ng-container>

	<ng-container *ngIf="productionCatalogue">
		<app-comment-form name="comments" [productionCatalogue]="productionCatalogue" [launch]="launch"></app-comment-form>
	</ng-container>

	<ng-container *ngIf="launch">
		<ng-container *ngIf="articles$ | async as articles">
			<br>
			<app-production-catalogue-launch-article-form
				[prodCat]="productionCatalogue"
				[readonly]="_readonly">
			</app-production-catalogue-launch-article-form>
		</ng-container>
	</ng-container>

	<app-invalid-feedback></app-invalid-feedback>
</form>
