<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{'db.updates-viewer.title' | translate}}</h1>
	</div>

	<app-tsv-file-selector (flightDatabaseSelected)="flightDatabaseSelected($event)" (tsvFileSelected)="tsvFileSelected($event)"></app-tsv-file-selector>

	<div>
		<ng-container *ngIf="selectedFlightDatabaseTsvFile$ | async as selectedFile">
			<div class="d-flex flex-row">
				<app-dropdown-multiselect [providedData]="viewSelectionDropdownData"
										  (selection)="updateVisibleColumns($event)"
										  placeholder="db.updates-viewer.view">
				</app-dropdown-multiselect>

				<div class="m-2 pt-2">
					<input id="updates-only-checkbox" class="form-check-input shadow-lg" type="checkbox" ngModel (ngModelChange)="updateUpdatesOnly($event)">
					<label class="form-check-label ms-2" for="updates-only-checkbox">
						{{"db.updates-viewer.updates-only" | translate}}
					</label>
				</div>
			</div>
			<app-table [id]="'tsv-diff-line-table'"
					   [headers]="headers"
					   [resetSearch]="resetSearch$"
					   [resetTriggersSearch]="false"
					   (search)="search($event)">
				<ng-container *ngIf="searchResult as page else noResults">
					<tr *ngFor="let tsvDiffLine of page.content; let i  = index"
						[class.added]="tsvDiffLine.deltaType === 'ADDED'"
						[class.deleted]="tsvDiffLine.deltaType === 'DELETED'">
						<ng-container *ngFor="let header of headers">
							<td *ngIf="header.visible">
								<ng-container *ngIf="header.name === DIFF_COLUMN">
									<span *ngIf="tsvDiffLine.deltaType === 'ADDED'">+</span>
									<span *ngIf="tsvDiffLine.deltaType === 'DELETED'">-</span>
								</ng-container>
								<ng-container *ngIf="header.name !== DIFF_COLUMN">
									{{ tsvDiffLine.content[header.name] }}
								</ng-container>
							</td>
						</ng-container>
					</tr>
				</ng-container>
			</app-table>

			<app-pagination *ngIf="searchResult" [page]="searchResult" (pageEvent)="loadPage($event)"></app-pagination>

			<div class="d-flex flex-row justify-content-end gap-3 align-items-center">
				<div class="mt-3">
					<app-direct-download [exportFunction]="getExportFunction()" [filePrefix]="'navdb_textual_viewer'"></app-direct-download>
				</div>
				<div class="d-inline-flex flex-row justify-content-end w-100 mt-3">
					<button id="reset-search-button" type="button" class="btn btn-primary" (click)="resetSearch()">{{'db.updates-viewer.reset-search-button' | translate}}</button>
				</div>
			</div>
		</ng-container>
	</div>
    <app-spinner class="center" *ngIf="loading"></app-spinner>
</div>

<ng-template #noResults>
	<tr>
		<td colspan="100%">
			<p class="empty-state-text my-4 justify-content-center">{{'db.updates-viewer.no-results' | translate}}</p>
		</td>
	</tr>
</ng-template>
