<app-base-modal [title]="i18nTitle | translate" [disabled]="submitting">
	<app-project-form #form="projectForm" [project]="project" [readonly]="submitting"/>
	<ng-container actions>
		<button id="submit-project" type="button" class="btn btn-primary"
				(click)="submitForm(form.getRequest())"
				[disabled]="form.isInvalid() || submitting">
			{{ i18nSubmit | translate }}
		</button>
	</ng-container>
</app-base-modal>
