import {Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {finalize, Observable, Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SourceRadicalIdentifier} from '../_model/source-radical-identifier';
import {SourceRadicalIdentifierService} from '../_service/source-radical-identifier.service';
import {UpdateForm} from '../../shared/modal/update.form';
import {CreateOrUpdateSourceRadicalIdentifierRequest} from '../_model/createOrUpdateSourceRadicalIdentifierRequest';
import {ModularFormComponent} from '../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-radical-identifier-modal',
	templateUrl: './source-radical-identifier-form-modal.component.html'
})
export class SourceRadicalIdentifierFormModalComponent extends ModularFormComponent implements OnDestroy, UpdateForm<SourceRadicalIdentifier> {

	submitting = false;
	sourceRadicalIdentifier: SourceRadicalIdentifier;

	private subscription = new Subscription();

	constructor(public activeModal: NgbActiveModal,
				private formBuilder: FormBuilder,
				private sourceRadicalIdentifierService: SourceRadicalIdentifierService) {
		super('source-radical-identifier');

		this.form.addControl('name', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('usualDesignation', new FormControl('', [Validators.maxLength(255)]));
	}

	setContent(content: SourceRadicalIdentifier): void {
		this.sourceRadicalIdentifier = content;
		this.form.patchValue(this.sourceRadicalIdentifier);
	}

	close(): void {
		if (!this.submitting) {
			this.activeModal.dismiss();
		}
	}

	save(): void {
		if (this.submitting) {
			return;
		}
		this.submitting = true;
		this.subscription.add(this.doSave()
			.pipe(
				finalize(() => {
					this.submitting = false;
				})
			).subscribe((sourceRadicalIdentifier) => {
				this.activeModal.close(sourceRadicalIdentifier);
			})
		);
	}

	doSave(): Observable<SourceRadicalIdentifier> {
		if (!this.sourceRadicalIdentifier) {
			return this.sourceRadicalIdentifierService.create(this.createRequest());
		} else {
			return this.sourceRadicalIdentifierService.update(this.sourceRadicalIdentifier.uuid, this.createRequest());
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private createRequest(): CreateOrUpdateSourceRadicalIdentifierRequest {
		return {
			name: this.form.get('name').value,
			usualDesignation: this.form.get('usualDesignation').value
		};
	}
}
