<div class="d-flex flex-column p-5">

	<div class="d-flex flex-row mb-3 justify-content-between">
		<h1>{{ 'notice.upload.title' | translate }}</h1>
	</div>

	<div class="container-xxl px-0 mx-0">

		<ng-container *ngIf="step === 'INPUT'">
			<app-upload-notice-form (submitForm)="this.submit($event)" [reset$]="reset$"/>
		</ng-container>

		<ng-container *ngIf="step === 'PROCESSING'">
			<app-upload-notice-progress (resetAll)="this.reset()"
										[selectedFiles]="result.selectedFiles"
										[request]="result.request"/>
		</ng-container>

	</div>

</div>
