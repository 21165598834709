import {NgModule} from '@angular/core';
import {ValidatedInputComponent} from './validated-input.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [
		ValidatedInputComponent
	],
	exports: [
		ValidatedInputComponent
	],
	imports: [
		ReactiveFormsModule,
		TranslateModule,
		CommonModule,
		NgbModule
	]
})
export class ValidatedInputModule {

}
