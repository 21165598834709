import {NgModule} from '@angular/core';
import {PaginationComponent} from './pagination.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';

@NgModule({
	declarations: [
		PaginationComponent
	],
	exports: [
		PaginationComponent
	],
	imports: [
		TranslateModule,
		CommonModule
	]
})
export class PaginationModule {

}
