<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{'job.overview.title' | translate}}</h1>
	</div>

	<div *ngIf="jobs$ | async as jobs">
		<div *ngIf="jobs && jobs.length > 0; else noJobs">
			<app-table [id]="'job-overview-table'"
					   [headers]="headers"
					   [headerNamePrefix]="'job.overview.headers.'"
					   [addButtonColumn]="true"
					   [nrOfButtonColumns]="1">
				<ng-container>
					<tr *ngFor="let job of jobs; let i  = index">
						<td>{{ job.latestStateChange }}</td>
						<td>{{ job.flightDatabaseName }}</td>
						<td>{{ job.tsvFileName }}</td>
						<td>{{ job.status }}</td>
						<td>
							<div id="check-job-{{i}}" *permissionsAllowed="['UPDATE_JOBS']"
								 class="d-flex justify-content-end align-items-center">
								<i *ngIf="job.status === 'PROCESSING' || job.status === 'FAILURE'" id="reprocess-job-{{i}}"
								   class="ti ti-reload me-2 fs-4 pointer"
								   appConfirmationModal
								   [ngbTooltip]="'job.overview.reprocess-tooltip' | translate"
								   (confirmAction)="reprocess(job)"
								   confirmationMessage="{{getConfirmationMessage() }}">
								</i>
								<i *ngIf="job.status === 'PROCESSING'" id="cancel-job-{{i}}"
								   class="ti ti-trash text-danger me-2 fs-4 pointer"
								   appConfirmationModal
								   [ngbTooltip]="'job.overview.cancel-tooltip' | translate"
								   (confirmAction)="cancel(job)"
								   confirmationMessage="{{getConfirmationMessage() }}">
								</i>
							</div>
						</td>
					</tr>
				</ng-container>
			</app-table>
		</div>
	</div>
</div>

<ng-template #noJobs>
	<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
		<p class="empty-state-text mb-4">{{'job.overview.no-content' | translate}}</p>
	</div>
</ng-template>
