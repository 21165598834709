<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{'user.overview.title' | translate}}</h1>
	</div>

	<div *ngIf="page$ | async as page">
		<div *ngIf="page.totalElements > 0 || hasSearchCriteria(); else noUsers">

			<div class="d-inline-flex flex-row justify-content-between w-100 mb-3">
				<div class="d-inline-flex gap-2">
					<app-multilevel-dropdown-multiselect [providedData]="filterDropdownData$ | async"
														 (selection)="filtersSelected($event)"
														 translationPrefix="user.overview."
														 placeholder="general.filter"
														 placeholderIcon="ti-filter">
					</app-multilevel-dropdown-multiselect>

					<app-search inputFieldId="user-search-field"
								placeHolder="user.overview.search.placeholder"
								(searchEvent)="search($event)">
					</app-search>
				</div>

				<button id="open-create-user-modal" type="button" *permissionsAllowed="['CREATE_USERS']" class="btn btn-primary" (click)="openCreateModal()">
					<i class="ti ti-plus me-2 fs-4"></i>{{'user.overview.create-button' | translate}}
				</button>
			</div>

			<app-table [addButtonColumn]="true" [headers]="headers" [headerNamePrefix]="'user.overview.headers.'" [id]="'user-table'">
				<ng-container *ngIf="page.totalElements > 0 else noResults">
					<tr *ngFor="let user of page.content; let i = index">
						<td>{{ user.customer?.name }}</td>
						<td>{{ user.userName }}</td>
						<td>{{ user.firstName }}</td>
						<td>{{ user.lastName }}</td>
						<td>{{ user.creationDate }}</td>
						<td>{{ user.email }}</td>
						<td>{{ user.type }}</td>
						<td>{{ user.role.name}}</td>
						<td>
							<div class="d-flex justify-content-end align-items-center">
								<i id="edit-user-{{i}}" *permissionsAllowed="['UPDATE_USERS']" class="ti ti-pencil float-end me-2 fs-4 pointer" (click)="openUpdateModal(user)"></i>
								<i id="delete-user-{{i}}" *permissionsAllowed="['DELETE_USERS']" class="ti ti-trash float-end text-danger me-2 fs-4 pointer"
								   appConfirmationModal
								   (confirmAction)="deleteUser(user)"
								   confirmationMessage="{{getConfirmationMessage()}}"
								>
								</i>
								<i id="view-user-{{i}}" class="ti ti-eye me-2 fs-4 pointer" (click)="openViewModal(user)"></i>
							</div>
						</td>
					</tr>
				</ng-container>
			</app-table>
			<app-pagination [page]="page" (pageEvent)="loadPage($event)"></app-pagination>
			<div class="d-inline-flex flex-row justify-content-end w-100 mb-3 mt-3">
				<app-direct-download [exportFunction]="getExportFunction()" [filePrefix]="'user_overview'"></app-direct-download>
			</div>
		</div>
	</div>

</div>

<ng-template #noResults>
	<tr>
		<td colspan="100%">
			<p class="empty-state-text my-4 justify-content-center">{{'user.overview.search.no-results' | translate}}</p>
		</td>
	</tr>
</ng-template>

<ng-template #noUsers>
	<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
		<p class="empty-state-text mb-4">{{'user.overview.no-content' | translate}}</p>
		<button class="btn btn-primary" (click)="openCreateModal()"><i class="ti ti-plus me-2 fs-4"></i>{{'user.overview.create-button' | translate}}</button>
	</div>
</ng-template>
