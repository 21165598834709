import {UploadProgressStatus} from './upload.progress.status';

export class UploadProgress {
	file: string;
	totalBytes: number;
	uploadedBytes: number;
	status: UploadProgressStatus;

	constructor(file: string, status: UploadProgressStatus , totalBytes?: number, uploadedBytes?: number) {
		this.file = file;
		this.totalBytes = totalBytes;
		this.uploadedBytes = uploadedBytes;
		this.status = status;
	}

	getTotalSizeLabel(): string {
		return this.bytesToSize(this.totalBytes);
	}

	getUploadedSizeLabel(): string {
		return this.bytesToSize(this.uploadedBytes);
	}

	getProgress(): number {
		return (this.uploadedBytes / this.totalBytes) * 100;
	}

	isUploaded(): boolean {
		return this.uploadedBytes === this.totalBytes;
	}

	bytesToSize(bytes: number): string {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) {
			return '0 Bytes';
		}
		const i = Math.floor(Math.log(bytes) / Math.log(1024));
		return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
	}
}
