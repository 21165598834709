<div class="d-flex flex-column p-3 p-lg-5 bg-paper-plane h-100">

	<div class="row position-relative">
		<div class="col-12 col-lg-8">

			<div class="pb-3">
				<h1>{{'contact.title' | translate}}</h1>
			</div>

			<div class="bg-lightgrey rounded p-3">

				<!--				Technical issues-->
				<div class="mb-3">
					<div class="d-flex flex-row">
						<i class="ti ti-settings-question fs-4 me-2"></i>
						<h2 class="h5 mb-4">{{'contact.technical-issues.title' | translate}}</h2>
					</div>

					<div class="mb-2">
						<span [innerHTML]="'contact.technical-issues.database-content' | translate"></span>
					</div>
					<div class="flex-row mb-2">
						<i class="ti ti-mail me-2"></i>
						<a href="mailto:database.support@fr.thalesgroup.com"
						   class="textlink text-decoration-none text-decoration-none">database.support&#64;fr.thalesgroup.com</a>
					</div>

					<div class="mb-2">
						<span>{{'contact.technical-issues.urgent' | translate}}</span>
					</div>
					<div class="flex-row mb-2">
						<i class="ti ti-phone me-2"></i>
						<a href="tel:+330561190789" class="textlink text-decoration-none">+33 (0) 5 61 19 07 89</a>
					</div>
					<br>
				</div>

				<div class="mb-5">
					<div class="mb-2">
						<span [innerHTML]="'contact.technical-issues.access-or-subscription' | translate"></span>
					</div>
					<div class="flex-row">
						<i class="ti ti-mail me-2"></i>
						<a href="mailto:support@aviobook.aero" class="textlink text-decoration-none">support&#64;aviobook.aero</a>
					</div>
				</div>

				<!--				Subscriptions-->
				<div class="mb-5">
					<div class="d-flex flex-row">
						<i class="ti ti-rss fs-4 me-2"></i>
						<h2 class="h5 mb-4">{{'contact.subscriptions.title' | translate}}</h2>
					</div>
					<div class="mb-2">
						<span [innerHTML]="'contact.subscriptions.description' | translate"></span>
					</div>
					<div class="flex-row">
						<i class="ti ti-mail me-2"></i>
						<a href="mailto:aviodata.subscription@aviobook.aero" class="textlink text-decoration-none">aviodata.subscription&#64;aviobook.aero</a>
					</div>
				</div>

				<!--				Other-->
				<div class="mb-3">
					<div class="d-flex flex-row">
						<i class="ti ti-zoom-question fs-4 me-2"></i>
						<h2 class="h5 mb-4">{{'contact.other.title' | translate}}</h2>
					</div>
					<div class="mb-2">
						<span>{{'contact.other.description' | translate}}</span>
					</div>
					<div class="flex-row">
						<i class="ti ti-mail me-2"></i>
						<a href="mailto:aviodata.subscription@aviobook.aero" class="textlink text-decoration-none">aviodata.subscription&#64;aviobook.aero</a>
					</div>
				</div>
			</div>

		</div>
	</div>

</div>
