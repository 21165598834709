<label class="form-label big">{{ 'customer.form.' + name + '.label' | translate }}</label><br>
<span class="empty-state-text subtitle mb-3" *ngIf="!getSubscriptionCount()">
	{{ 'customer.form.' + name + '.placeholder' | translate }}
</span>
<ng-container *ngFor="let x of getControls(); let i = index">
	<div class="d-flex align-items-center mb-3 flex-nowrap gap-2">
		<app-modular-form-control-select [name]="name + '.' + i + '.articleUuid'"
										 [options]="getArticles(i) | async"
										 layout="flex-inline"
										 [hideLabel]="true"/>

		<app-modular-form-control-date [name]="name + '.' + i + '.startDate'" layout="flex-inline"/>

		<app-modular-form-control-date [name]="name + '.' + i + '.endDate'" layout="flex-inline"/>

		<app-modular-form-control-input *ngIf="offset"
										type="number"
										[name]="name + '.' + i + '.offset'"
										layout="flex-inline"
										[showTooltip]="true"/>

		<i class="ti ti-trash float-end text-danger me-2 fs-4 pointer"
		   *ngIf="!formArray.disabled"
		   (click)="removeSubscription(i)">
		</i>
	</div>
</ng-container>

<button type="button" (click)="addSubscription()"
		*ngIf="!formArray.disabled"
		class="btn btn-secondary mb-2">{{ 'customer.form.' + name + '.add' | translate }}
</button>
