import {Directive, inject, Input, OnInit} from '@angular/core';
import {ControlContainer, FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NamedFormGroup} from '../named-form-group';

@Directive()
export abstract class ModularFormControl implements OnInit {

	@Input() public name: string;
	@Input() public i18nPrefix = '';

	formControl: FormControl;

	protected readonly translateService = inject(TranslateService);
	protected readonly controlContainer = inject(ControlContainer);

	ngOnInit(): void {
		if (this.controlContainer) {
			if (this.name) {
				this.formControl = this.controlContainer.control.get(this.name) as FormControl;
			} else {
				console.warn('Missing name from host element of the component');
			}
			if (this.controlContainer.control instanceof NamedFormGroup && !this.i18nPrefix) {
				this.i18nPrefix = this.getForm().name + '.form';
			}
		} else {
			console.warn('Can\'t find parent FormGroup directive');
		}

	}

	protected getForm(): NamedFormGroup {
		return this.controlContainer.control as NamedFormGroup;
	}

	public id(): string {
		const name = this.name.replaceAll('.', '-');
		return `input-${name}`;
	}

	public translate(key: string): string {
		const prefix = this.getI18nPrefix();
		return this.translateService.instant(prefix + '.' + key);
	}

	public getI18nPrefix(): string {
		const name = this.name.replaceAll(/\.\d+/g, '');
		return this.i18nPrefix + '.' + name;
	}

	public translateLabel(): string {
		return this.translate('label');
	}

	public translatePlaceholder(): string {
		return this.translate('placeholder');
	}

	public translateError(key: string): string {
		return this.translate('validation.' + key);
	}

	hasErrors(): boolean {
		return this.formControl.errors !== null;
	}

	getErrors(): string[] {
		return Object.keys(this.formControl.errors);
	}

}
