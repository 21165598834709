import {UploadProgressStatus} from './upload.progress.status';
import {ErrorResponse} from '../error-handling/error-response';

export class GeneralUploadProgress {
	status: UploadProgressStatus;
	errors: ErrorResponse[];

	constructor(status: UploadProgressStatus, errors?: ErrorResponse[]) {
		this.status = status;
		this.errors = errors;
	}
}
