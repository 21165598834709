<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{'db.overview.title' | translate}}</h1>
	</div>

	<div class="d-inline-flex flex-row justify-content-between w-100 mb-3">
		<ul class="nav nav-pills gap-3 secondary">
			<li class="nav-item">
				<button class="nav-link" [class.active]="(tab$ | async) === 'ALL'" (click)="switchTab('ALL')">
					{{ 'db.overview.tab.all' | translate }}
				</button>
			</li>
			<li class="nav-item">
				<button class="nav-link" [class.active]="(tab$ | async) === 'ACTIVE'" (click)="switchTab('ACTIVE')">
					{{ 'db.overview.tab.active' | translate }}
				</button>
			</li>
			<li class="nav-item">
				<button class="nav-link" [class.active]="(tab$ | async) === 'INACTIVE'" (click)="switchTab('INACTIVE')">
					{{ 'db.overview.tab.inactive' | translate }}
				</button>
			</li>
		</ul>

		<a [routerLink]="'/dbupload'" *permissionsAllowed="['UPLOAD_DBS']" class="text-decoration-none">
			<button type="button" class="btn btn-primary">
				<i class="ti ti-upload me-2 fs-4"></i>{{'general.upload' | translate}}
			</button>
		</a>

	</div>

	<div *ngIf="page$ | async as page">
		<div *ngIf="page.totalElements > 0 || hasSearchCriteria() || searchValue; else noFlightDatabases">

			<div class="d-inline-flex gap-2">
				<app-multilevel-dropdown-multiselect [providedData]="filterDropdownData$ | async"
													 (selection)="filtersSelected($event)"
													 translationPrefix="db.overview."
													 placeholder="general.filter"
													 placeholderIcon="ti-filter">
				</app-multilevel-dropdown-multiselect>
				<app-search inputFieldId="db-search-field"
							placeHolder="db.overview.search.placeholder"
							(searchEvent)="search($event)">
				</app-search>
			</div>


			<app-table [id]="'flightdatabase-table'"
					   [headers]="headers"
					   [headerNamePrefix]="'db.overview.headers.'"
					   [addButtonColumn]="true"
					   [nrOfButtonColumns]="3">
				<ng-container *ngIf="page.totalElements > 0 else noResults">
					<tr *ngFor="let database of page.content; let i  = index">
						<td>{{ database.articleReference }}</td>
						<td>{{ database.databaseName }}</td>
						<td>{{ database.usualDesignation }}</td>
						<td>{{ database.airacCycle ? database.airacCycle : 'NA' }}</td>
						<td>{{ database.releaseNumber }}</td>
						<td>{{ database.effectiveDate }}</td>
						<td *permissionsAllowed="['VIEW_END_VALIDITY_DATE']">{{ database.endValidityDate }}</td>
						<td *permissionsAllowed="['VIEW_UPLOAD_TIME']">{{ database.uploadTime }}</td>
						<td>
							<div class="d-flex justify-content-end align-items-center">
								<button class="float-start btn btn-table w-lg-td btn-download"
										[disabled]="database.flaggedAsError"
										id="download-db-{{i}}"
										(click)="openDownloadModal(database.uuid, database.files)">
									<i class="ti ti-file-download fs-4 me-2">
									</i>
									{{database.files.length}} {{'general.files' | translate}}
								</button>
							</div>
						</td>
						<td>
							<ng-container *ngIf="database.flaggedAsError; else dbNotFlagged">
								<div [ngbTooltip]="'db.overview.flag-tooltip.flagged' | translate" placement="top-right"
									 class="text-center">
									<ng-container *ngIf="hasPermission('UPDATE_DBS') | async; else noPermissionFlagged">
										<i id="unflag-db-{{i}}"
										   class="ti ti-flag float-end me-2 fs-4 link-warning pointer"
										   appConfirmationModal
										   [danger]="false"
										   [hasFooter]="false"
										   [modalIcon]="'ti-flag'"
										   (confirmAction)="unflagDatabaseAsError(database.uuid)"
										   [confirmationMessage]="'db.unflag.confirmation-message.are-you-sure' | translate">
										</i>
									</ng-container>
									<ng-template #noPermissionFlagged>
										<i id="unflag-db-{{i}}" class="ti ti-flag fs-4 link-warning"></i>
									</ng-template>
								</div>
							</ng-container>
							<ng-template #dbNotFlagged>
								<div [ngbTooltip]="'db.overview.flag-tooltip.not-flagged' | translate"
									 placement="top-right" class="text-center">
									<ng-container
										*ngIf="hasPermission('UPDATE_DBS') | async; else noPermissionNotFlagged">
										<i id="flag-db-{{i}}" class="ti ti-flag float-end me-2 fs-4 pointer link-success"
										   appConfirmationModal
										   [danger]="false"
										   [hasFooter]="false"
										   [modalIcon]="'ti-flag'"
										   (confirmAction)="flagDatabaseAsError(database.uuid)"
										   [confirmationMessage]="'db.flag.confirmation-message.are-you-sure' | translate">
										</i>
									</ng-container>
									<ng-template #noPermissionNotFlagged>
										<i id="flag-db-{{i}}" class="ti ti-flag fs-4 link-success"></i>
									</ng-template>
								</div>
							</ng-template>
						</td>
						<td>
							<i id="delete-database-{{i}}" *permissionsAllowed="['DELETE_DBS']"
							   class="ti ti-trash float-end text-danger me-2 fs-4 pointer"
							   (click)="openDeleteModal(database)">
							</i>
						</td>
					</tr>
				</ng-container>
			</app-table>
			<app-pagination [page]="page" (pageEvent)="loadPage($event)"></app-pagination>
			<div class="d-inline-flex flex-row justify-content-end w-100 mb-3 mt-3">
				<app-direct-download [exportFunction]="getExportFunction()" [filePrefix]="'db_download_overview'"></app-direct-download>
			</div>
		</div>
	</div>

</div>

<ng-template #noFlightDatabases>
	<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
		<p class="empty-state-text mb-4">{{'db.overview.no-content' | translate}}</p>
	</div>
</ng-template>

<ng-template #noResults>
	<tr>
		<td colspan="100%">
			<p class="empty-state-text my-4 justify-content-center">{{'db.overview.search.no-results' | translate}}</p>
		</td>
	</tr>
</ng-template>
