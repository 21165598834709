import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from './confirmation/confirmation.modal.component';
import {ConfirmationModalConfiguration} from './confirmation/confirmation.modal.configuration';
import {filter, merge, Observable, tap} from 'rxjs';
import {ToastrMessageType} from '../toastr/toastr.message.type';
import {ToastrMessageService} from '../toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ModalService {

	constructor(private ngbModal: NgbModal,
				private toastrMessageService: ToastrMessageService,
				private translateService: TranslateService) {
	}

	public showConfirmationModal(configuration: ConfirmationModalConfiguration): void {
		const modalRef = this.ngbModal.open(ConfirmationModalComponent, {modalDialogClass: 'no-scroll'});
		modalRef.componentInstance.modalConfiguration = configuration;
	}

	public openCreateModal(form: any, translationType: string, largeModal = false): Observable<any> {
		const modalRef = this.ngbModal.open(form, this.getModalOptions(largeModal));
		return merge(modalRef.closed, modalRef.dismissed)
			.pipe(
				filter((result) => result && typeof result != 'number'),
				tap(() => this.toastSuccess('create', translationType))
			);
	}


	public openUpdateModal<T>(form: any, content: T, translationType: string, largeModal = false): Observable<any> {
		const modalRef = this.ngbModal.open(form, this.getModalOptions(largeModal));
		modalRef.componentInstance.setContent(content);
		return merge(modalRef.closed, modalRef.dismissed)
			.pipe(
				filter((result) => result && typeof result != 'number'),
				tap(() => this.toastSuccess('update', translationType))
			);
	}

	toastSuccess(actionType: string, objectType: string): void {
		this.toastrMessageService.show({
			type: ToastrMessageType.SUCCESS,
			messageHTML: `<i class="ti ti-check"></i> ${this.translateService.instant(`${objectType}.${actionType}.success-message`)}`
		});
	}


	private getModalOptions(largeModal: boolean): any {
		let options = {};
		if (largeModal) {
			options = {
				windowClass: 'modal-large',
				modalDialogClass: 'modal-dialog-xl'
			};
		}
		return options;
	}
}
