import {Directive, Input} from '@angular/core';
import {NamedFormGroup} from '../named-form-group';

@Directive()
export class ModularFormComponent {

	form: NamedFormGroup;

	constructor(name: string) {
		this.form = new NamedFormGroup(name, {});
	}

	public isInvalid(): boolean {
		return this.form.invalid;
	}

	@Input() set readonly(value: boolean) {
		if (value) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}
}
