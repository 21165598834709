import {Directive, inject} from '@angular/core';
import {Notice} from '../notice';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Program} from '../../model/program';
import {NoticeFile} from '../notice-file';
import {DownloadNoticeFileComponent} from '../../download-notice-file/download-notice-file.component';
import {ViewNoticeModalComponent} from '../view-notice-modal/view-notice-modal.component';

@Directive()
export abstract class BaseOverviewComponent {

	private modalService = inject(NgbModal);

	protected getProgramsCommaSeparated(programs: Array<Program>): string {
		return programs.map(p => p.name).join(', ');
	}

	protected  openDownloadModal(noticeUuid: string, files: Array<NoticeFile>): void {
		const modalRef = this.modalService.open(DownloadNoticeFileComponent, {windowClass: 'small-footer'});
		modalRef.componentInstance.setContext(noticeUuid, files);
	}

	protected openViewModal(notice: Notice): void {
		const modalRef = this.modalService.open(ViewNoticeModalComponent, {
			windowClass: 'modal-large',
			modalDialogClass: 'modal-dialog-l'
		});
		modalRef.componentInstance.notice = notice;
	}

}
