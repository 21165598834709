import {of, switchMap} from 'rxjs';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LogoutService {
	private _window = window;

	constructor(private oidcSecurityService: OidcSecurityService) {
	}

	logout(): void {
		this.oidcSecurityService.getConfiguration().pipe(
			switchMap((configuration) => {
				if (configuration.authority.indexOf('localhost') === -1) {
					const cognitoUrl = configuration.authWellknownEndpoints.authorizationEndpoint.replace('/oauth2/authorize', '');
					this.oidcSecurityService.logoffLocal();

					this._window.location.href = cognitoUrl + `/logout` +
						`?client_id=${configuration.clientId}` +
						`&logout_uri=${configuration.postLogoutRedirectUri}`;
					return of();
				} else {
					return this.oidcSecurityService.logoffAndRevokeTokens();
				}
			})
		).subscribe();
	}
}
