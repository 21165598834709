import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseUploadComponent} from '../../../shared/upload/base-upload.component';
import {Observable} from 'rxjs';
import {UploadProgressStatus} from '../../../shared/upload/upload.progress.status';
import {GeneralUploadProgress} from '../../../shared/upload/general.upload.progress';
import {NoticeService} from '../../_service/notice.service';
import {HttpEvent} from '@angular/common/http';
import {CreateNoticeRequest} from '../../model/create-notice-request';

@Component({
	selector: 'app-upload-notice-progress',
	templateUrl: './upload-notice-progress.component.html'
})
export class UploadNoticeProgressComponent extends BaseUploadComponent implements OnInit, OnDestroy {

	@Output() public resetAll = new EventEmitter();

	@Input() selectedFiles: File[];

	@Input() request: CreateNoticeRequest;

	constructor(private noticeService: NoticeService) {
		super();
	}

	ngOnInit(): void {
		this.upload(this.selectedFiles);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	reset(): void {
		this.resetAll.emit();
	}

	override doUploadFile(file: File, uploadUuid: string): Observable<HttpEvent<any>> {
		return this.noticeService.uploadFile(file, uploadUuid);
	}

	override createEntity(uploadUuid: string): Observable<any> {
		this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.PROCESSING));
		return this.noticeService.createNotice(this.request, uploadUuid);
	}

}
