import {ToastrMessage} from './toastr.message';

export class ToastrInstance {
	constructor(private id: number, private toastrMessage: ToastrMessage) {
	}

	public getId(): number {
		return this.id;
	}

	public getToastrMessage(): ToastrMessage {
		return this.toastrMessage;
	}
}
