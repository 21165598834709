import {Component, Input} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {formatDate} from '@angular/common';

@Component({
	selector: 'app-direct-download',
	templateUrl: 'direct-download.component.html'
})
export class DirectDownloadComponent {
	@Input() exportFunction = new Observable<HttpResponse<Blob>>();
	@Input() filePrefix: string;

	private subscription = new Subscription();

	export(): void {
		const subscription = this.exportFunction.subscribe(response => {
			const a = document.createElement('a');
			a.download = this.filePrefix + '_' + formatDate(Date.now(), 'yyyy-MM-dd_HH-mm-ss', 'en') + '.csv';
			a.href = window.URL.createObjectURL(response.body);
			a.click();
			subscription.unsubscribe();
		});
	}


}
