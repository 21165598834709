import {NgModule} from '@angular/core';
import {AuthInterceptor, AuthModule} from 'angular-auth-oidc-client';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {environment} from '../../environments/environment';

@NgModule({
	imports: [AuthModule.forRoot({
		config: {
			authority: environment.authentication.authority,
			redirectUrl: window.location.origin + '/callback',
			postLogoutRedirectUri: window.location.origin,
			clientId: environment.authentication.clientId,
			scope: 'openid',
			responseType: 'code',
			silentRenew: true,
			useRefreshToken: true,
			renewTimeBeforeTokenExpiresInSeconds: 30,
			secureRoutes: [environment.authentication.secureRoutes],
			ignoreNonceAfterRefresh: true
		}
	})],
	exports: [AuthModule],
	providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}]
})
export class AuthConfigModule {
}
