import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Project} from '../_model/project';
import {CreateOrUpdateProjectRequest} from '../_model/create-or-update-project.request';

@Injectable()
export class ProjectService {

	readonly baseUrl = environment.backend_url + '/project';

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<Array<Project>> {
		return this.http.get<Array<Project>>(this.baseUrl);
	}

	getSelectableProjects(): Observable<Array<Project>> {
		return this.http.get<Array<Project>>(this.baseUrl + '/selectable');
	}

	search(pageNumber: number): Observable<Page<Project>> {
		return this.http.get<Page<Project>>(this.baseUrl + '/search', {params: {'page': pageNumber}});
	}

	create(project: CreateOrUpdateProjectRequest): Observable<Project> {
		return this.http.post<Project>(this.baseUrl, project);
	}

	update(uuid: string, project: CreateOrUpdateProjectRequest): Observable<Project> {
		return this.http.put<Project>(`${this.baseUrl}/${uuid}`, project);
	}

	delete(projectUuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${projectUuid}`);
	}

}
