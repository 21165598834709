import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Customer} from '../_model/customer';
import {finalize, Observable, Subscription, tap} from 'rxjs';
import {CreateOrUpdateCustomerRequest} from '../_model/create-or-update-customer.request';
import {CustomerService} from '../_service/customer.service';
import {UpdateForm} from '../../shared/modal/update.form';

@Component({
	selector: 'app-customer-form-modal',
	templateUrl: './customer-form-modal.component.html'
})
export class CustomerFormModalComponent implements UpdateForm<Customer>, OnDestroy {

	customer: Customer;
	readonly: boolean;
	submitting = false;
	subscription = new Subscription();

	constructor(private activeModal: NgbActiveModal,
				private customerService: CustomerService) {
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	setContent(content: Customer): void {
		this.customer = content;
	}

	get i18nTitle(): string {
		if (this.readonly) {
			return 'customer.view.modal.title';
		}
		return this.customer ? 'customer.update.modal.title' : 'customer.create.modal.title';
	}

	get i18nSubmit(): string {
		if (this.readonly) {
			return 'general.close';
		}
		return this.customer ? 'general.save' : 'general.create';
	}

	submitForm(request: CreateOrUpdateCustomerRequest): void {
		this.submitting = true;

		this.subscription.add(this.submit(request).pipe(
			tap(() => {
				this.activeModal.close(true);
			}),
			finalize(() => this.submitting = false)
		).subscribe());
	}

	private submit(request: CreateOrUpdateCustomerRequest): Observable<Customer> {
		if (this.customer) {
			return this.customerService.update(this.customer.uuid, request);
		}
		return this.customerService.create(request);
	}
}
