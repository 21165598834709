<main class="d-inline-flex w-100 flex-column">
	<div class="d-flex flex-nowrap">
		<ng-container *ngIf="showSupportingComponents$ | async else noSupportingComponents">
			<div class="col-3 col-xxl-2 bg-lightgrey min-vh-100">
				<app-sidebar></app-sidebar>
			</div>
			<div class="col-auto col-md-9 col-xxl-10 bg-white">
				<router-outlet></router-outlet>
			</div>
		</ng-container>

		<ng-template #noSupportingComponents>
			<div class="col py-3 bg-white">
				<router-outlet></router-outlet>
			</div>
		</ng-template>
	</div>
</main>

<app-toastr-messages *ngIf="showSupportingComponents$ | async"></app-toastr-messages>

