<div class="d-flex bd-highlight mb-3">
	<div><h1>{{ titleTranslationKey | translate }}</h1></div>
	<div class="ms-auto mt-4">
		<div class="d-flex justify-content-end gap-3">
			<button id="close" type="button" class="btn btn-secondary" (click)="close()">{{ "general.go-back" | translate }}
			</button>
			<button id="submit-article" type="button" class="btn btn-primary"
					*ngIf = "!readonly"
					(click)="submitForm(articleForm.getArticle())"
					[disabled]="articleForm.isInvalid() || submitting">
				{{ getSubmitTranslationKey() | translate }}
			</button>
		</div>
	</div>
</div>

<app-article-form #articleForm="articleForm" [article]="article" [readonly]="submitting || readonly"/>
