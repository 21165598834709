import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {DropdownMultiselectComponent} from './dropdown-multiselect.component';
import {MultilevelDropdownMultiselectComponent} from './multilevel-dropdown-multiselect.component';
import {DropdownDataFactory} from './dropdown-data.factory';
import {DropdownComponent} from './dropdown.component';
import {FormsModule} from '@angular/forms';

@NgModule({
	declarations: [
		DropdownMultiselectComponent,
		MultilevelDropdownMultiselectComponent,
		DropdownComponent
	],
	exports: [
		DropdownMultiselectComponent,
		MultilevelDropdownMultiselectComponent,
		DropdownComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule
	],
	providers: [
		DropdownDataFactory
	]
})
export class DropdownModule {

}
