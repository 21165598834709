<div class="modal-header">
	<h1 class="modal-title fs-5">
		<span>{{ "db.overview.title" | translate }}</span>
	</h1>
	<button type="button" class="btn-close" (click)="close()" [disabled]="isDownloading()"></button>
</div>
<div class="modal-body" style="padding: unset">
	<div class="d-flex flex-column justify-content-center">
		<app-table [id]="'flightdatabase-table'"
				   [headerNamePrefix]="'db.overview.headers.'"
				   [headers]="[]"
				   [addButtonColumn]="true"
				   [removeStickyHeader]="true">
			<ng-container *ngFor="let file of files; let i  = index">
				<tr>
					<td id="file-name-{{i}}" class="w-25 text-center">{{ file.fileName }}</td>
					<td id="file-description-{{i}}" class="w-25 text-center">{{ file.description }}</td>
					<ng-container *ngIf="downloadingIndex === i || downloadingIndex === -1; else downloadButton">
						<td class="w-25 text-center">
							<div class="spinner-border"></div>
						</td>
					</ng-container>
					<ng-template #downloadButton>
						<td class="w-25 text-center" [class.text-light]="isDownloading()">
							<i role="button" id="download-{{i}}" class="ti ti-file-download fs-4"
							   [class.cursor-default]="isDownloading()"
							   (click)="downloadFiles(file.uuid, i)"></i>
						</td>
					</ng-template>
				</tr>
				<tr *ngIf="progress[file.uuid]">
					<td colspan="3">
						<ngb-progressbar type="success" [value]="progress[file.uuid].progress" height="0.5rem" class="w-100"></ngb-progressbar>
					</td>
				</tr>
			</ng-container>
		</app-table>
	</div>
</div>
<div class="modal-footer">
	<button id="close" type="button" class="btn btn-secondary" (click)="close()"
			[disabled]="isDownloading()">{{ "general.go-back" | translate }}
	</button>
	<button id="download-all" type="button" class="btn btn-primary" (click)="downloadFiles(null, -1)"
			[disabled]="isDownloading()">
		<span>{{ "general.download-all" | translate }}</span>
	</button>
</div>
