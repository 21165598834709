import {Component} from '@angular/core';
import {ToastrMessageService} from './toastr.message.service';
import {ToastrMessageType} from './toastr.message.type';
import {ToastrInstance} from './toastr.instance';

@Component({
	selector: 'app-toastr-messages',
	templateUrl: './toastr.messages.component.html'
})
export class ToastrMessagesComponent {
	constructor(public toastrMessageService: ToastrMessageService) {
	}

	public getAutoHide(toastrInstance: ToastrInstance): boolean {
		return !toastrInstance.getToastrMessage().sticky;
	}

	public getClassNames(toastrInstance: ToastrInstance): string {
		switch (toastrInstance.getToastrMessage().type) {
			case ToastrMessageType.SUCCESS:
				return 'alert alert-dismissible alert-success';
			case ToastrMessageType.INFO:
				return 'alert alert-dismissible alert-info';
			case ToastrMessageType.WARNING:
				return 'alert alert-dismissible alert-warning';
			case ToastrMessageType.ERROR:
				return 'alert alert-dismissible alert-danger';
		}
	}
}
