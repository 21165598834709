import {NgModule} from '@angular/core';
import {LogoutComponent} from './logout.component';
import {TranslateModule} from '@ngx-translate/core';
import {LogoutService} from './logout.service';

@NgModule({
	declarations: [
		LogoutComponent
	],
	imports: [
		TranslateModule
	],
	exports: [
		LogoutComponent
	],
	providers: [
		LogoutService
	]
})
export class LogoutModule {

}
