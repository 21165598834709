import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize, Observable, Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UpdateForm} from '../../../shared/modal/update.form';
import {Program} from '../../model/program';
import {ProgramService} from '../../_service/program.service';

@Component({
	selector: 'app-create-or-update-modal-program',
	templateUrl: './create-or-update-program-modal.component.html'
})
export class CreateOrUpdateProgramModalComponent implements OnInit, OnDestroy, UpdateForm<Program> {
	programForm: FormGroup;
	submitting = false;
	program: Program;
	formGroup: FormGroup;
	private subscription = new Subscription();

	constructor(public activeModal: NgbActiveModal,
				private formBuilder: FormBuilder,
				private programService: ProgramService
	) {
		this.programForm = formBuilder.group({
			'name': formBuilder.control('', [Validators.required, Validators.maxLength(255)])
		});
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({});
	}

	setContent(program: Program): void {
		this.program = program;
		this.programForm.controls['name'].setValue(program.name);
	}

	getControlFromGroup(name: string, group: AbstractControl<any, any>): FormControl<any> {
		return (group as FormGroup).controls[name] as FormControl<any>;
	}

	getControl(name: string): FormControl<any> {
		return this.programForm.controls[name] as FormControl<any>;
	}

	close(): void {
		if (!this.submitting) {
			this.activeModal.dismiss();
		}
	}

	save(): void {
		if (this.submitting) {
			return;
		}
		this.submitting = true;
		this.subscription.add(this.doSave()
			.pipe(
				finalize(() => {
					this.submitting = false;
				})
			).subscribe((program) => {
				this.activeModal.close(program);
			})
		);
	}

	doSave(): Observable<Program> {
		if (!this.program) {
			return this.programService.create({
				name: this.programForm.value.name
			});
		} else {
			return this.programService.update(
				this.program.uuid, {
					name: this.programForm.value.name
				});
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
