<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{'program.overview.title' | translate}}</h1>
	</div>

	<div *ngIf="page$ | async as page">
		<div *ngIf="page.totalElements > 0; else noPrograms">
			<div class="d-inline-flex flex-row justify-content-end w-100 ">
				<button id="open-create-program-modal" type="button" *permissionsAllowed="['CREATE_PROGRAMS']" class="btn btn-primary" (click)="openCreateModal()"><i class="ti ti-plus me-2 fs-4"></i>{{'program.overview.create-button' | translate}}</button>
			</div>

			<app-table [addButtonColumn]="true" [headers]="headers" [headerNamePrefix]="'program.overview.headers.'" [id]="'program-table'">
				<tr *ngFor="let program of page.content; let i = index">
					<td>{{ program.name}}</td>
					<td>
						<div class="d-flex justify-content-end align-items-center">
							<i id="edit-program-{{i}}" *permissionsAllowed="['UPDATE_PROGRAMS']" class="ti ti-pencil float-end me-2 fs-4 pointer" (click)="openUpdateModal(program)"></i>
							<i id="delete-program-{{i}}" *permissionsAllowed="['DELETE_PROGRAMS']" class="ti ti-trash float-end text-danger me-2 fs-4 pointer"
							   appConfirmationModal
							   (confirmAction)="deleteProgram(program)"
							   confirmationMessage="{{getConfirmationMessage()}}"
							>
							</i>
						</div>
					</td>
				</tr>
			</app-table>
			<app-pagination [page]="page" (pageEvent)="loadPage($event)"></app-pagination>
		</div>
	</div>

	<ng-template #noPrograms>
		<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
			<p class="empty-state-text mb-4">{{'program.overview.no-content' | translate}}</p>
			<button class="btn btn-primary" (click)="openCreateModal()"><i class="ti ti-plus me-2 fs-4"></i>{{'program.overview.create-button' | translate}}</button>
		</div>
	</ng-template>

</div>
